import axios from "axios";
import pakjegemak from "./_pakjegemak";

const addresses = [...document.getElementsByClassName('js-address')];

addresses.map((address, index) => {
    const addressField = address.getElementsByClassName('js-address-address')[0];
    const addressAdditionField = address.getElementsByClassName('js-address-address-addition')[0];
    const numberField = address.getElementsByClassName('js-address-number')[0];
    const zipcodeField = address.getElementsByClassName('js-address-zipcode')[0];
    const placeField = address.getElementsByClassName('js-address-place')[0];
    const countryField = address.getElementsByClassName('js-address-country')[0];

    const preview = address.getElementsByClassName('js-address-preview')[0];
    const previewAddress = preview.getElementsByClassName('js-address-preview-address')[0];
    const edit = preview.getElementsByClassName('js-address-edit')[0];
    const addressWarning = address.getElementsByClassName('js-address-warning')[0];

    const manualFieldsContainer = address.getElementsByClassName('js-address-manual')[0];

    const timeframes = document.getElementsByClassName('js-timeframes')[0];
    const isBusinessToggle = document.getElementsByClassName('js-checkout-business-toggle')[0];
    const vatField = document.getElementsByClassName('js-checkout-vat')[0];
    const vatLabelField = document.getElementsByClassName('js-checkout-vat-label')[0];

    let customAddress = false;

    const autocompleteAddress = async (e = null) => {
        if(countryField.value === 'nl') {
            const zipcode = zipcodeField.value.trim();
            const number = numberField.value;

            if(e !== null && e.currentTarget.classList.contains('js-address-address-addition')) {
                previewAddress.innerText = addressField.value +' '+ number + addressAdditionField.value +', '+ placeField.value;
            } else if(zipcode.match(/^[1-9][0-9]{3}[\ ]*[A-z]{2}$/i) && number !== '') {
                try {
                    let response = await axios.get(`/bjax/webshop/postcodefill/?postcode=${zipcode}&huisnr=${number}`);

                    if(response.status === 200) {
                        addressField.value = response.data.street;
                        placeField.value = response.data.city;

                        addressField.dispatchEvent(new Event('change'));
                        placeField.dispatchEvent(new Event('change'));

                        previewAddress.innerText = response.data.street +' '+ response.data.houseNumber + addressAdditionField.value +', '+ response.data.city;
                        manualFieldsContainer.classList.add('d-none');
                        preview.classList.remove('d-none');

                        if(timeframes) {
                            timeframes.classList.remove('d-none');
                        }
                    }
                } catch (e) {
                    manualFieldsContainer.classList.remove('d-none');
                    addressWarning.classList.remove('d-none');
                    preview.classList.add('d-none');
                    pakjegemak.resetTimeframes();
                    customAddress = true;

                    if(timeframes) {
                        timeframes.classList.add('d-none');
                    }
                }
            }
        }
    };

    const countryChangeEvent = () => {
        console.log(isBusinessToggle);


        if(countryField.value === 'be' && (typeof isBusinessToggle !== 'undefined' ? isBusinessToggle.checked === true : true) && index === 0 && vatField) {
            vatField.required = true;
            vatLabelField.querySelector('.input__label--optional').classList.add('d-none');
        } else if(countryField.value === 'nl' && index === 0 && vatField) {
            vatField.required = false;
            vatLabelField.querySelector('.input__label--optional').classList.remove('d-none');

        } else if(countryField.value === 'nl' && !customAddress) {
            manualFieldsContainer.classList.add('d-none');
            zipcodeField.setAttribute('pattern', '\\s*[1-9]{1}[0-9]{3}[ ]?[A-z]{2}\\s*');
            zipcodeField.setAttribute('title', '1111AA');
            autocompleteAddress();
        } else {
            manualFieldsContainer.classList.remove('d-none');
            zipcodeField.setAttribute('pattern', '[0-9]{4}');
            zipcodeField.setAttribute('title', '1111');
            preview.classList.add('d-none');

            if(addressWarning) {
                addressWarning.classList.add('d-none');
            }
        }
    };

    countryChangeEvent();
    countryField.addEventListener('change', countryChangeEvent);

    edit.addEventListener('click', () => {
        manualFieldsContainer.classList.remove('d-none');
        preview.classList.add('d-none');
        if(addressWarning) {
            addressWarning.classList.add('d-none');
        }
        customAddress = true;
    });

    autocompleteAddress();
    zipcodeField.addEventListener('change', autocompleteAddress);
    numberField.addEventListener('change', autocompleteAddress);
    addressAdditionField.addEventListener('change', autocompleteAddress);
});
