import React from 'react';
import zilvercms from "../scripts/_utils";

export const ReviewStar = props => {
    const id = Math.floor(Math.random() * 100000);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 28" data-no-fill>
            <defs>
                <linearGradient id={`${id}-${props.token}`}>
                    <stop offset={props.offset || 1} stopColor={props.color} />
                    <stop offset={props.offset || 1} stopColor={props.colorAlt || props.color} />
                </linearGradient>
            </defs>

            <path d="M25.961 10.33l-7.713-1.18a.896.896 0 01-.676-.507l-3.417-7.3a.895.895 0 00-1.623 0l-3.417 7.3a.896.896 0 01-.676.506L.726 10.331a.9.9 0 00-.506 1.516l5.637 5.791a.9.9 0 01.243.773l-1.323 8.13a.897.897 0 001.318.931l6.815-3.777c.27-.15.597-.15.867 0l6.815 3.777a.897.897 0 001.318-.931l-1.323-8.13a.9.9 0 01.244-.773l5.636-5.791a.9.9 0 00-.506-1.516z" fill={`url(#${`${id}-${props.token}`})`} fillRule="nonzero" />
        </svg>
    );
}

const ReviewStars = props => {
    return (
        <span className={`stars${props.className ? ` ${props.className}` : ''}`}>
            {[1,2,3,4,5].map(index => (
                <i key={index} className={`star${props.average ? ' star--average' : ''} mr-1`}>
                    {index <= props.rating ? (
                        <ReviewStar token={index} color="#ffcd00" />
                    ) : (props.rating.toString().includes('.') && index === Math.ceil(props.rating) ? (
                        <ReviewStar token={index} offset={`.${props.rating.toString().split('.')[1]}`} color="#ffcd00" colorAlt="#d1d4d9" />
                    ) : (
                        <ReviewStar token={index} color="#d1d4d9" />
                    ))}
                </i>
            ))}

            {props.withLabel && (
                <span className="shop-reviews__rating-label">
                    {zilvercms.translationHelper(`webshop.reviews.rating-labels.${Math.round(props.rating)}`)}
                </span>
            )}
        </span>
    );
};

export default ReviewStars;
