import axios from 'axios';
import zilvercms from "./_utils";

$('.js-product-review-form').each((i, element) => {
    const $form = $(element);
    const $captchaNumber = $form.find('.js-product-review-number');
    const $captchaExpval = $form.find('.js-product-review-expval');
    const $formFooter = $form.find('.js-product-review-footer');
    const $messageSuccess = $form.find('.js-product-review-success');

    $form.on('submit', e => {
        e.preventDefault();

        if($captchaNumber && $captchaExpval) {
            $captchaExpval.val($captchaNumber.val());
        }
        
        const formData = [];
        const reviewData = $form.serializeArray();
        const messageJson = {
            title: '',
            message: '',
            recommends: 0,
            positive: [],
            negative: [],
        };

        reviewData.map(field => {
            if(['product_id', 'review_name', 'review_points', 'review_number', 'review_expval'].includes(field.name)) {
                formData.push([field.name, field.value]);
            } else if(['review_title', 'review_message'].includes(field.name)) {
                messageJson[field.name.replace('review_', '')] = field.value;
            } else if(field.name === 'review__recommends' && field.value === 'on') {
                messageJson.recommends = 1;
            } else if(field.name === 'review_pros[]' && field.value !== '') {
                messageJson.positive.push(field.value)
            } else if(field.name === 'review_cons[]' && field.value !== '') {
                messageJson.negative.push(field.value)
            }
        });


        messageJson.positive = messageJson.positive.join(' || ');
        messageJson.negative = messageJson.negative.join(' || ');
        formData.push(['review_message', JSON.stringify(messageJson)]);

        axios.post('/productreview', zilvercms.serialize(formData)).then(() => {
            $messageSuccess.removeClass('d-none');
            $formFooter.children().toggleClass('d-none');
        })
    });
});