import zilvercms from "./_utils";
import customer from "./_customer";

const successRegister = document.querySelector('.js-success-register');

if(![null, undefined].includes(successRegister)) {
    successRegister.addEventListener('submit', async e => {
        e.preventDefault();

        const registerErrorMessage = successRegister.getElementsByClassName('js-success-register-error')[0];
        registerErrorMessage.classList.add('d-none');

        const password = successRegister.getElementsByClassName('js-success-register-password')[0];
        const passwordRepeat = successRegister.getElementsByClassName('js-success-register-password-repeat')[0];

        if(password.value !== passwordRepeat.value) {
            passwordRepeat.parentElement.setAttribute('data-error', zilvercms.translationHelper('customer.password.not-equal'));
            passwordRepeat.parentElement.removeAttribute('data-valid');

            return false;
        }

        if(customer.validatePasswordStrength(password.value) < 2) {
            password.parentElement.setAttribute('data-error', zilvercms.translationHelper('customer.password.not-strong'));
            password.parentElement.removeAttribute('data-valid');

            return false;
        }

        const formData = new FormData(successRegister);

        let response = await customer.registerAfterCheckout('individual', formData);

        if(response === true) {
            window.location.href = '/Customer/';
        } else {
            registerErrorMessage.innerText = response;
            registerErrorMessage.classList.remove('d-none');
        }
    });
}
