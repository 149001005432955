import axios from "axios";

const questionsForm = document.querySelector('.js-questions-form');

if(questionsForm !== null) {
    const successMessage = questionsForm.querySelector('.js-questions-success');
    const footerButtons = [...questionsForm.querySelectorAll('.js-questions-button')];

    questionsForm.addEventListener('submit', async e => {
        e.preventDefault();

        const formData = new FormData(questionsForm);

        let response = await axios.post('/bjax/ProductQuestion/Ask', formData);

        if(response.status === 200) {
            successMessage.classList.remove('d-none');
            footerButtons.map(button => {
                button.classList[button.classList.contains('d-none') ? 'remove' : 'add']('d-none');
            })
        }
    });
}
