export const addToCart = () => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
        event:     'add_to_cart',
        ecommerce: {
            items: [{
                item_name:     window.productDetails.name,
                item_id:       window.productDetails.id,
                price:         window.productDetails.price,
                item_brand:    'Datona',
                item_category: window.productDetails.category,
                quantity:      '1'
            }]
        }
    });
};

export const addToCartUpsell = data => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
        event:     'add_to_cart',
        ecommerce: {
            items: [{
                item_name:     data.name,
                item_id:       data.id,
                price:         data.price,
                item_brand:    'Datona',
                item_category: data.category,
                quantity:      '1'
            }]
        }
    });
};


export const removeFromCart = data => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
        event:     'remove_from_cart',
        ecommerce: {
            "currency": "EUR",
            "items":    [{
                item_name:     data.name,
                item_id:       data.id,
                price:         data.price,
                item_brand:    data.brand,
                item_category: data.category,
                item_variant:  data.variant,
                quantity:      data.count,
                index:         data.index
            }]
        }
    });
};

export const addPaymentInfo = (method, items) => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
        event:     'add_payment_info',
        ecommerce: {
            "payment_type": method,
            "items": items
        }
    });
};

export const addShippingInfo = (method, items) => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event:     'Add_shipping_info',
        ecommerce: {
            "shipping_tier": method,
            "items": items
        }
    });
};
