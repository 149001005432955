$(document).ready(function() {
    var formNL = function(element) {
        try {
            var countryCode = $(element).parents('form').find('select[name*="countryCode"]').val();
            return (countryCode === 'NL');
        } catch(error) {
            return false;
        }
    }

    $.validator.addMethod('strongPassword', function(value, element) {
        var $field = $(element);
        var $strength = $field.parent().find('.js-password-strength');
        var strength = {
            0: "worst",
            1: "bad",
            2: "weak",
            3: "good",
            4: "strong",
        };

        var score = 0;
        // If password is longer than 6 characters.
        if(value.length > 6) {
            score += 1;
        }
        // If password contains both lower and uppercase characters, increase strength value.
        if (value.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
            score += 1;
        }
        // If it has numbers and characters, increase strength value.
        if (value.match(/([a-zA-Z])/) && value.match(/([0-9])/)) {
            score += 1;
        }
        // If it has one special character, increase strength value.
        if (value.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
            score += 1;
        }

        $strength.removeAttr('data-password-strength');
        $strength.attr('data-password-strength', strength[score]);

        if(score < 2) {
            return false;
        } else {
            return true;
        }
    }, 'Het wachtwoord is te zwak. Gebruik minimaal 6 tekens waaronder cijfers, hoofdletters en speciale tekens voor een sterk wachtwoord.');

    // Zipcode validation
    $.validator.addMethod('zipcodeNL', function(value, element) {
        if(!formNL(element)) {
            return true;
        } if(value === '' || (value.match(/^[1-9][0-9]{3}[\ ]*[A-z]{2}$/i))) {
            return true;
        } else {
            return false;
        }
    }, 'Vul een geldige postcode in, bijv: 1234AB');

    // Zipcode auto fill
    $('.js-cm-register-zipcode, .js-cm-register-number').on('change', function() {
        var zipcode = $('.js-cm-register-zipcode').val().split(' ').join('');
        var number = $('.js-cm-register-number').val();

        if(formNL(this) && zipcode !== '' && number !== '') {
            $.ajax({
                dataType: 'json',
                url: '/bjax/webshop/postcodefill/',
                data: {
                    postcode: zipcode,
                    huisnr: number
                },
                success: function(json) {
                    if(json) {
                        $('.js-cm-register-street').val(json.street).valid();
                        $('.js-cm-register-city').val(json.city).valid();
                    }
                }
            });
        }
    });

    $('.js-cm-form').each(function() {
        var $form = $(this);

        $form.validate({
            rules: {
                'invoice_address[zip_code]': {
                    zipcodeNL: true
                },
                zip_code: {
                    zipcodeNL: true
                }
            },
            errorPlacement: function(error, el) {
                $(el).parent().attr('data-error-message', $(error).text());
            },
            highlight: function(el) {},
            unhighlight: function(el) {
                $(el).parent().removeAttr('data-error-message');
            },
            submitHandler: function() {
                var action = $form.attr('action');
                var method = $form.attr('method');
                var data = $form.serializeArray();
                var updating = (action.indexOf('/edit/') > -1);
                var checkout = $form.data('checkout');
                var $button = $form.find('.btn-primary');
                var buttonOriginalContent = $button.text();
                var buttonWidth = $button.width();

                $button.addClass('loading disabled');
                $button.width(buttonWidth);
                $button.html('<i class="fa fa-cog fa-spin"></i>');

                $.ajax({
                    url: action,
                    method: method,
                    data: data,
                    dataType: 'json'
                }).done(function(result) {
                    $form.find('.js-cm-error-alert').text('').hide();
                    $form.find('.js-cm-field').removeAttr('data-error-message');

                    if(updating) {
                        var $staticData = $form.prev('.js-cm-static');

                        for (i = 0; i < data.length; i++) {
                            var field = data[i];
                            var $el = $staticData.find('.js-cm-static-' + field.name);

                            if(field.name === 'countryCode') {
                                var newValue = {
                                    'nl': 'Nederland',
                                    'be': 'België'
                                }[field.value];
                            } else {
                                var newValue = field.value;
                            }

                            $el.text(newValue);
                        }

                        $staticData.removeClass('editing');
                    } else if (checkout) {
                        window.location.href = '/checkout/';
                    } else {
                        window.location.href = '/Customer/Orders';
                    }
                }).fail(function(result) {
                    $form.find('.js-cm-error-alert').text(result.responseJSON.error_message).show();
                    $form.find('.js-cm-field').removeAttr('data-error-message');
                    var messages = result.responseJSON.error_fields;
                    for(var field in messages) {
                        var $field = $form.find('.js-cm-field-' + field.replace('.', '_'));
                        var msg = messages[field];
                        $field.attr('data-error-message', msg);
                    }
                }).always(function(result) {
                    $button.removeClass('loading disabled');
                    $button.removeAttr('style');
                    $button.html(buttonOriginalContent);
                });
            }
        });

        $form.find('.js-validate-password-strength').each(function() {
            $(this).rules('add', {
                required: true,
                strongPassword: 2
            })
        });
    });

    $('.js-cm-edit').each(function() {
        var $editBtn = $(this);

        $editBtn.on('click', function(e) {
            e.preventDefault();
            var $group = $editBtn.parents('.js-cm-static');
            $group.addClass('editing');
            return false;
        });
    });

    $('.js-cm-cancel-edit').each(function() {
        var $cancelLink = $(this);

        $cancelLink.on('click', function(e) {
            e.preventDefault();
            var $group = $cancelLink.parents('.js-cm-form').prev('.js-cm-static');
            $group.removeClass('editing');
            return false;
        });
    });

    $('.js-cm-logout').each(function() {
        var $logoutBtn = $(this);

        $logoutBtn.on('click', function(e) {
            e.preventDefault();
            $.ajax({
                url: '/bjax/Customer/logout',
                method: 'POST'
            }).always(function(result) {
                if(result.status == 205) {
                    window.location.href = '/Customer/';
                }
            });
            return false;
        });
    });
});
