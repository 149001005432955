import zilvercms from "./_utils";
import axios from "axios";

const filters = document.querySelector('.js-filters');

if(![null, undefined].includes(filters)) {
    let triggers, filterElements, optionElements, priceFilterMin, priceFilterMax, filterRemoveTriggers;

    const initFilters = () => {
        triggers = [...document.querySelectorAll('.js-filters-trigger')];
        filterElements = [...filters.querySelectorAll('.js-filters-filter')];
        optionElements = [...filters.querySelectorAll('.js-filter-option')];
        priceFilterMin = filters.querySelector('.js-filters-price-min');
        priceFilterMax = filters.querySelector('.js-filters-price-max');
        filterRemoveTriggers = [...document.querySelectorAll('.js-filters-filter-remove')];

        optionElements.map(option => {
            if(option.type === 'checkbox') {
                option.addEventListener('click', () => {
                    if(option.checked) {
                        [...filters.querySelectorAll(`.js-filter-option[name="${option.name}"]:not([value="${option.value}"]):checked`)].map(sibling => sibling.checked = false);
                    }
                });
            }
        });

        if(zilvercms.viewport.isEqualOrSmallerThan('md')) {
            triggers.map(trigger => trigger.addEventListener('click', toggleFilters));

            filterElements.map((filter, index) => {
                const heading = filter.querySelector('.js-filters-filter-heading');
                const options = filter.querySelector('.js-filters-filter-options');

                filter.setAttribute('data-open', '');

                heading.addEventListener('click', () => {
                    if(filter.hasAttribute('data-open')) {
                        options.style.height = 0;
                        filter.removeAttribute('data-open');
                    } else {
                        filter.setAttribute('data-open', '');
                        options.style.height = `${options.scrollHeight}px`;
                    }
                });

                if(index > 0) {
                    heading.dispatchEvent(new Event('click'));
                }
            });
        } else {
            filterElements.map(filter => {
                const options = filter.querySelector('.js-filters-filter-options');
                const more = filter.querySelector('.js-filters-filter-options-more');

                if(![null, undefined].includes(more)) {
                    options.style.height = `${options.children[5].offsetTop}px`;

                    more.addEventListener('click', () => {
                        if(more.hasAttribute('data-toggled')) {
                            options.style.height = `${options.children[5].offsetTop}px`;
                            more.removeAttribute('data-toggled');
                        } else {
                            options.style.height = `${options.scrollHeight}px`;
                            more.setAttribute('data-toggled', '');
                        }
                    })
                }
            })
        }

        filterRemoveTriggers.map(filterRemoveTrigger => {
            filterRemoveTrigger.addEventListener('click', () => {
                const dataset = filterRemoveTrigger.dataset
                const input = filters.querySelector(`input[name="${dataset.name}"][value="${dataset.value}"]`);

                if(input.type === 'checkbox') {
                    input.checked = false;
                } else {
                    input.value = '';
                }

                submitHandler();
            })
        });
    }

    const parsePriceValue = value => {
        if(value !== '') {
            value = parseInt(value) * 100;
        }

        return value;
    };

    const submitHandler = () => {
        priceFilterMin.style.opacity = '.2';
        priceFilterMax.style.opacity = '.2';

        priceFilterMin.value = parsePriceValue(priceFilterMin.value);
        priceFilterMax.value = parsePriceValue(priceFilterMax.value);

        if(zilvercms.viewport.isEqualOrSmallerThan('md')) {
            updateFilterOptions();
        } else {
            filters.submit();
        }
    }

    const updateFilterOptions = async () => {
        filters.setAttribute('data-loading', '');

        const formData = new FormData(filters);
        const searchUrl = new URLSearchParams(formData).toString();

        let response = await axios.get(`${filters.action}?${searchUrl}`);

        if(response.status === 200) {
            filters.innerHTML = $(response.data).find('.js-filters').html();
        }

        initFilters();
        filters.removeAttribute('data-loading');
    }

    const toggleFilters = () => {
        if(filters.hasAttribute('data-open')) {
            filters.removeAttribute('data-open');
            zilvercms.unlockBody();
        } else {
            zilvercms.lockBody();
            filters.setAttribute('data-open', '');
        }
    }

    filters.addEventListener('change', e => {
        if(e.target.name === 'sort_name') {
            [...filters.querySelectorAll('[name="sort_name"]')].map(option => {
                option.checked = false;
                option.nextElementSibling.checked = false;
            });

            e.target.checked = true
            e.target.nextElementSibling.checked = true;
        }

        if(e.target.type !== 'number') {
            submitHandler();
        }
    });

    filters.addEventListener('submit', submitHandler);

    initFilters();
}
