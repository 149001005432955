import axios from 'axios';
import zilvercms from "./_utils";
import * as datalayer from "./_datalayer";
import {horizontalHandler} from './horizontal';

$('.js-cart').each((i, element) => {
    const $cart = $(element);
    const cartItemCount = document.querySelector('.js-cart-item-count');

    const getCurrentCart = async () => {
        let response = await axios.get('/cart/');

        if(response.status === 200) {
            updateCartAfterRequest(response.data);
        }
    };

    const updateCartAfterRequest = data => {
        const $html = $(data);
        const $newCart = $html.filter('.js-cart').html();

        cartItemCount.setAttribute('data-count', $html.find('.js-cart-item-count').data('count'));

        $cart.html($newCart);
        $cart.find('.js-horizontal').each((i, element) => horizontalHandler(element));
        $cart.removeClass('cart--loading');

        if($('.js-discount-error').length > 0) {
            $cart.find('.js-cart-discount-toggle').trigger('click');
        }
    };

    // Wishlist - Remove product from cart if added to wishlist
    // $cart.on('click', '.js-cart-product-wishlist', e => {
    //     const $wishlistText = $cart.find('.js-cart-wishlist-text');
    //     const $quantityField = $(e.currentTarget).parents('.js-cart-product').find('.js-cart-product-quantity');
    //     const productId = $quantityField.data('productId');
    //
    //     if(!wishlist.containsProductId(productId)) {
    //         $quantityField.val(0);
    //         $quantityField.trigger('change');
    //
    //         $wishlistText.removeClass('d-none');
    //     } else if(wishlist.productIds.length === 1) {
    //         $wishlistText.addClass('d-none');
    //     }
    //
    //     return true;
    // });

    // Quantity
    $cart.on('click', '.js-cart-product-quantity-minus', e => {
        const quantityField = e.currentTarget.nextElementSibling;
        const quantity = parseInt(quantityField.value);

        if(quantity > 0) {
            quantityField.value = quantity - 1;

            $(quantityField).trigger('change');
        }
    });

    $cart.on('click', '.js-cart-product-quantity-plus', e => {
        const quantityField = e.currentTarget.previousElementSibling;
        const quantity = parseInt(quantityField.value);

        quantityField.value = quantity + 1;

        $(quantityField).trigger('change');
    });

    $cart.on('click', '.js-cart-product-remove', e => {
        const $quantityField = $(e.currentTarget).parents('.js-cart-product').find('.js-cart-product-quantity');

        datalayer.removeFromCart(JSON.parse(e.currentTarget.dataset.data));
        $quantityField.val(0);
        $quantityField.trigger('change');
    });

    $cart.on('change', '.js-cart-product-quantity', async e => {
        const quantityField = e.currentTarget;
        const quantity = parseInt(quantityField.value);

        $cart.addClass('cart--loading');

        const formData = new FormData();
        formData.append('command', 'updatecart');
        formData.append('cartitem_count_'+ quantityField.dataset.cartId, quantity);

        let response = await axios.post('/cart/', formData);

        if(response.status === 200) {
            updateCartAfterRequest(response.data);
        }
    });

    // Discount
    $cart.on('click', '.js-cart-discount-toggle', e => {
        const discountForm = $cart.find('.js-cart-discount-form')[0];

        if(discountForm.scrollHeight === discountForm.clientHeight) {
            discountForm.style.height = discountForm.scrollHeight + 'px';

            setTimeout(() => {
                discountForm.style.height = 0;
                e.currentTarget.classList.remove('cart__discount-toggle--toggled');
            });
        } else {
            e.currentTarget.classList.add('cart__discount-toggle--toggled');
            discountForm.style.height = discountForm.scrollHeight + 'px';

            setTimeout(() => {
                discountForm.style.height = 'auto';
            }, 200);
        }
    });

    $cart.on('keypress', '.js-cart-discount-field', e => {
        if(e.keyCode === 13) {
            e.preventDefault();
            $cart.find('.js-cart-discount-submit').trigger('click');
        }
    });

    $cart.on('click', '.js-cart-discount-submit', async () => {
        $cart.addClass('cart--loading');

        const formData = new FormData();
        formData.append('discountcode', $cart.find('.js-cart-discount-field').val());
        $cart.find('.js-cart-discount-field').parent().removeAttr('data-error');

        try {
            let response = await axios.post('/bjax/webshop/Adddiscountcode/', formData);

            if(response.status === 200) {
                await getCurrentCart()
            }
        } catch (e) {
            await getCurrentCart();

            const cartDiscountFieldParent = $cart.find('.js-cart-discount-field').parent().parent();

            $cart.find('.js-cart-discount-toggle').trigger('click');
            cartDiscountFieldParent.attr('data-touched', '');
            cartDiscountFieldParent.attr('data-error', zilvercms.translationHelper('webshop.discount.error'));
            $cart.removeClass('cart--loading');
        }
    });

    // Open discount on document ready
    $cart.find('.js-cart-discount-toggle').trigger('click');

    // Shipping methods
    $cart.on('change', '.js-cart-shipping-method', async () => {
        $cart.addClass('cart--loading');

        const formData = new FormData();
        formData.append('command', 'updatecart');
        formData.append('shipopt', $cart.find('.js-cart-shipping-method:checked').val());

        let response = await axios.post('/cart/', formData);

        if(response.status === 200) {
            updateCartAfterRequest(response.data);

            datalayer.addShippingInfo($cart.find('.js-cart-shipping-method:checked').data('name'),  window.items);
        }
    })

    // Upsells
    $cart.on('click', '.js-cart-upsell', async e => {
        e.preventDefault();

        const upsell = e.currentTarget;
        const upsellId = upsell.dataset.id;
        const formData = new FormData();

        $cart.addClass('cart--loading');
        upsell.classList.add('btn--loading');

        formData.append('command', 'addtocart');
        formData.append('productkey', upsellId);
        formData.append('count', 1);

        let response = await axios.post('/cart/', formData);

        if(response.status === 200) {
            updateCartAfterRequest(response.data);
            window.scrollTo({behavior: 'smooth', top: $cart.offset().top - 150})
        }
    })
});
