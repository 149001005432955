import zilvercms from "./_utils";

$('.js-price-toggle').each((i, element) => {
    const $toggle = $(element);

    let showPricesExcl = localStorage.getItem('showPricesExcl') === 'true';

    const updatePrices = () => {
        $('body').find('.js-price').each((i, element) => {
            const newPrice = showPricesExcl ? element.dataset.excl : element.dataset.incl;

            element.innerHTML = zilvercms.currencyHelper(newPrice)
        });
    };

    if(showPricesExcl) {
        $toggle.removeClass('toggle--active');
        updatePrices();
    }

    $toggle.on('click', () => {
        if(showPricesExcl) {
            $toggle.addClass('toggle--active');
            showPricesExcl = false;
        } else {
            $toggle.removeClass('toggle--active');
            showPricesExcl = true;
        }

        updatePrices();
        localStorage.setItem('showPricesExcl', showPricesExcl);
    });
});