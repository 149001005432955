import axios from "axios";

const dynamicForms = [...document.querySelectorAll('.js-dynamic-form')];

dynamicForms.map(dynamicForm => {
    const buttons = [...dynamicForm.querySelectorAll('.js-dynamic-form-button')];
    const success = document.querySelector('.js-dynamic-form-success');

    dynamicForm.addEventListener('submit', async e => {
        e.preventDefault();

        buttons.map(button => button.type === 'submit' ? button.classList.add('btn--loading') : null);

        const formData = new FormData(dynamicForm);
        const fields = [...dynamicForm.querySelectorAll('input, select')];
        fields.map(field => field.setAttribute('disabled', 'disabled'));

        let response = await axios.post(dynamicForm.action, formData);

        if(response.status === 200) {
            success.classList.remove('d-none');
            buttons.map(button => button.classList.toggle('d-none'));
        }
    });
});
