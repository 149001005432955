import zilvercms from "./_utils";

const loadSooqr = () => {
    window._wssq = window._wssq || [];

    const sooqrAccount = '119120-1';
    let setResizeFunction= false;

    window._wssq.push(['_load', { 'suggest' : { 'account' : 'SQ-' + sooqrAccount, 'version' : 4, fieldId : ['search', 'search-mobile']}}]);
    window._wssq.push(['suggest._setPosition', 'screen-middle', {top:0}]);
    window._wssq.push(['suggest._setLocale', 'nl_NL']);
    window._wssq.push(['suggest._excludePlaceholders', 'Search..']);
    window._wssq.push(['suggest._bindEvent', 'open', function() {
        if(!setResizeFunction) {$jQ( window ).resize(function() {if($jQ('.sooqrSearchContainer-' + sooqrAccount).is(':visible'))
        {websight.sooqr.instances['SQ-' + sooqrAccount].positionContainer(null, null, true);}});setResizeFunction = true;}
    }]);

    [...document.querySelectorAll('.js-searchform-overlay')].map(overlay => overlay.addEventListener('click', () => {
        websight.sooqr.instances[`SQ-${sooqrAccount}`].hideContainer();
    }));

    window._wssq.push(['suggest._bindEvent', 'open', () => {
        zilvercms.lockBody();
        document.body.setAttribute('data-search', '');
        infiniteScroll(sooqrAccount);
    }]);

    window._wssq.push(['suggest._bindEvent', 'close', () => {
        document.body.removeAttribute('data-search');
        zilvercms.unlockBody();
    }]);

    const infiniteScroll = sooqrAccount => {
        $jQ('.sooqrSearchContainer-' + sooqrAccount + ' .sooqrSearchResultsContainer').unbind('scroll.infiniteScroll').bind('scroll.infiniteScroll', function() {
            const $container = $jQ(this);
            const $results =  $container.find('.sooqrSearchResults');
            const $more = $container.find('.sqr-moreResults');

            if($more.is(':visible') && ($container.scrollTop() + $container.outerHeight()) > ($results.outerHeight() - 60)) {
                $more.trigger('click');
            }
        });
    }

    (function() {
        var ws = document.createElement('script'); ws.type = 'text/javascript'; ws.defer = true;
        ws.src = 'https://static.sooqr.com/sooqr.js';
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ws, s);
    })();
};

if(window.location.hash.includes('#sqr:')) {
    loadSooqr()
} else {
    setTimeout(() => {
        loadSooqr();
    }, 3000);
}
