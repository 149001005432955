$('.js-employees').each((i, element) => {
    const $employees = $(element);
    const $imageTrack = $employees.find('.js-employees-images');
    const $infoTracks = $employees.find('.js-employees-info');
    const $arrowsPrev = $employees.find('.js-employees-prev');
    const $arrowsNext = $employees.find('.js-employees-next');

    const uid = element.dataset.uid;

    $imageTrack.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '60px',
        infinite: true,
        accessibility: false,
        arrows: true,
        waitForAnimate: false,
        prevArrow: $arrowsPrev,
        nextArrow: $arrowsNext,
        dots: false,
        asNavFor: `.js-employees[data-uid="${uid}"] .js-employees-info`,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    centerPadding: 0,
                }
            }
        ]
    });

    $infoTracks.each((i, element) => {
        const $infoTrack = $(element);

        $infoTrack.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            infinite: true,
            accessibility: false,
            waitForAnimate: false,
            draggable: false,
            fade: true,
            arrows: false,
            dots: false,
            asNavFor: `.js-employees[data-uid="${uid}"] .js-employees-images`,
        });
    });
})
