import zilvercms from "./_utils";
import pakjegemak from "./_pakjegemak";

$(document).ready(function() {
    var $pickupLocation = $('.js-pickup-location');
    var $defaultOption = $('.js-pickup-location-select').find('option').first().clone();
    var $timeframe = $('.js-timeframe');

    var recievePickupLocations = function(zipcode) {
        if(/^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/.test(zipcode.trim())) {
            var $select = $('.js-pickup-location-select');
            $select.parent().addClass('loading');
            $('.js-pickup-location-select-label').text('Afhaalpunten ophalen...');
            $pickupLocation.parent().removeAttr('data-error');

            $.ajax({
                dataType: 'json',
                method: 'POST',
                url: '/bjax/postnl/getNearestLocations/',
                data: {
                    zipcode: zipcode,
                    countryCode: 'NL',
                    shippingMethodId: $('.js-checkout').data('shippingmethod-id')
                },
                success: function (json) {
                    $select.off('change.selectPickupPoint');
                    $select.empty();

                    if($select.hasClass('select2-hidden-accessible')) {
                        $select.select2('destroy');
                    }

                    if(json.locations.length > 0) {
                        $.each(json.locations, function (i) {
                            var distance = parseInt(this.distance);
                            if (distance < 1000) {
                                var distanceText = '< 1 km';
                            } else {
                                var distanceRounded = Math.round(distance / 100) / 10;
                                var distanceText = distanceRounded.toString().replace('.', ',') + ' km';
                            }

                            var $option = $('<option/>');
                            $option.val(this.name);
                            $option.text([
                                this.name + ',',
                                this.address.street,
                                this.address.houseNr,
                                this.address.city,
                                '(' + distanceText + ')'
                            ].join(' '));

                            $option.attr('data-location-name', this.name);
                            $option.attr('data-location-street', this.address.street);
                            $option.attr('data-location-streetnr', this.address.houseNr);
                            $option.attr('data-location-zipcode', this.address.zipcode);
                            $option.attr('data-location-city', this.address.city);
                            $option.attr('data-location-country', this.address.countryCode);
                            $option.attr('data-location-distance', distanceText);
                            $option.attr('data-location-label', [
                                this.name + ', ',
                                this.address.street,
                                this.address.houseNr + ',',
                                this.address.city
                            ].join(' '));

                            $select.append($option).valid();
                        });
                    } else {
                        $select.append($defaultOption)
                        $pickupLocation.parent().attr('data-error', 'Kon geen beschikbare afhaalpunten vinden, gebruik een andere postcode.');
                        $('.js-pickup-location-select-label').text('Selecteer afhaalpunt');
                    }

                    $select.on('change.selectPickupPoint', function() {
                        var $selected = $select.find('option:selected');

                        $('.js-pickup-location-street').val($selected.attr('data-location-street'));
                        $('.js-pickup-location-streetnr').val($selected.attr('data-location-streetnr'));
                        $('.js-pickup-location-zipcode').val($selected.attr('data-location-zipcode'));
                        $('.js-pickup-location-city').val($selected.attr('data-location-city'));
                        $('.js-pickup-location-country').val($selected.attr('data-location-country'));

                        if(json.locations.length > 0) {
                            $('.js-summary-pickup').html($selected.attr('data-location-label')).parents('.js-summary-delivery').fadeIn();
                        } else {
                            $('.js-summary-pickup').html('').parents('.js-summary-delivery').fadeOut();
                        }

                    });

                    $select.trigger('change');
                    $select.parent().removeClass('loading');
                    $('.js-pickup-location-select-label').text('Selecteer afhaalpunt');

                    if(json.locations.length > 0) {
                        if (zilvercms.viewport.isGreaterThan('xs')) {
                            $select.select2({
                                allowHtml:         true,
                                theme:             'flat',
                                templateResult:    function (data) {
                                    var $option = $(data.element);
                                    var name = data.id;
                                    var street = $option.data('location-street');
                                    var streetnr = $option.data('location-streetnr');
                                    var city = $option.data('location-city');
                                    var distance = $option.data('location-distance');

                                    var newOption = [
                                        '<div>',
                                        name,
                                        '<div class="float-right">',
                                        distance,
                                        '</div>',
                                        '<div class="text small muted">',
                                        street + ' ' + streetnr + ', ' + city,
                                        '</div>',
                                        '</div>'
                                    ];
                                    return $(newOption.join(''));
                                },
                                templateSelection: function (data) {
                                    var $option = $(data.element);
                                    var name = data.id;
                                    var street = $option.data('location-street');
                                    var streetnr = $option.data('location-streetnr');
                                    var city = $option.data('location-city');

                                    var newOption = [
                                        '<div>',
                                        name,
                                        '<span class="text muted ml-3">',
                                        street + ' ' + streetnr + ', ' + city,
                                        '</span>',
                                        '</div>'
                                    ];
                                    return $(newOption.join(''));
                                }
                            })
                        }
                    }
                },
                error: function(XMLHttpRequest) {
                    $select.append($defaultOption)
                    $pickupLocation.parent().attr('data-error', 'Kon geen beschikbare afhaalpunten vinden, gebruik een andere postcode.');
                    $('.js-pickup-location-select-label').text('Selecteer afhaalpunt');
                }
            });
        } else {
            $('.js-pickup-location').parent().
            removeAttr('data-success').
            attr('data-error', 'Vul een geldige postcode in, bijv: 1234AB');
        }
    }

    var recieveTimeframes = function() {
        var zipcode = $('.js-address-zipcode:visible').last().val();
        var streetnr = $('.js-address-number:visible').last().val();

        if(zipcode !== '' && /^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/.test(zipcode.trim()) && streetnr !== '') {
            var $selectDate = $('.js-timeframe');
            var $selectOption = $('.js-timeframe-options');
            var $summaryDate = $('.js-summary-timeframe-date');
            var $summaryOption = $('.js-summary-timeframe-option');

            $.ajax({
                dataType: 'json',
                method: 'POST',
                url: '/bjax/postnl/GetAvailableTimeFrames/',
                data: {
                    zipcode: zipcode,
                    houseNumber: streetnr,
                    countryCode: 'NL',
                    shippingMethodId: 1
                },
                success: function (json) {
                    var timeframes = json.available_times.timeFrames;
                    var dateOptions = {};

                    $selectDate.val('').find('.js-timeframe-day').remove();
                    pakjegemak.resetTimeframes();

                    if(timeframes.length) {
                        $.each(timeframes, function(i) {
                            var date = this.date;
                            var options = this.options;

                            if(options.length) {
                                if(!dateOptions[date]) {
                                    dateOptions[date] = [];

                                    var $option = $('<option/>');
                                    $option.val(this.date);
                                    $option
                                        .text(pakjegemak.formatTimeFrameDate(this))
                                        .addClass('js-timeframe-day');

                                    $selectDate.append($option);
                                }

                                dateOptions[date].push(options[0]);
                            }
                        });

                        $selectDate.parent().removeClass('loading');

                        $selectDate.on('change.selectDate', function() {
                            if($selectDate.val() === '') {
                                pakjegemak.resetTimeframes();
                            } else {
                                $selectDate.attr('name', 'data[timeframe][preferred_shipping_date]');
                                $selectOption.attr('name', 'data[timeframe][option]').empty();
                                $summaryDate.text($selectDate.find('option:selected').text()).parents('.js-summary-delivery').fadeIn();

                                var date = $selectDate.val();
                                var options = dateOptions[date];

                                $.each(options, function(i) {
                                    var $option = $('<option/>');
                                    var label = this.label;

                                    if(this.shipping > 0) {
                                        var price = zilvercms.currencyHelper(this.shipping);
                                        label += ' (+ ' + price + ')';
                                    }

                                    if(label === 'Hele dag') {
                                        label = this.label + ' (08.00 tot 21.30 uur)';
                                    } else if(label === 'Avond') {
                                        label = this.label + ' (17.30 tot 22.00 uur)';
                                    }

                                    $option.val(this.option).text(label).attr('data-price', this.shipping).addClass('js-timeframe-option');

                                    $selectOption.append($option);

                                    if(i === 0) {
                                        pakjegemak.updateSummaryPrice('shippingcost', this.shipping);
                                        pakjegemak.updateSummaryPrice('price', this.shipping);
                                    }
                                });

                                $selectOption.parent().removeClass('loading');
                                $selectOption.trigger('change');
                            }
                        });

                        $selectOption.on('change.selectOption', function() {
                            var $selectedOption = $selectOption.find('option:selected');
                            pakjegemak.updateSummaryPrice('shippingcost', $selectedOption.data('price'));
                            pakjegemak.updateSummaryPrice('price', $selectedOption.data('price'));

                            $summaryOption.text($selectedOption.text()).parents('.js-summary-delivery').fadeIn();
                        });
                    }
                },
                error: function(XMLHttpRequest) {
                    var error = XMLHttpRequest.responseJSON;
                }
            });
        }
    }

    if($pickupLocation.length) {
        if($pickupLocation.val() !== '') {
            recievePickupLocations($pickupLocation.val());
        }

        $pickupLocation.on('keyup.pickupLocation', function() {
            if($pickupLocation.val() !== '') {
                recievePickupLocations($pickupLocation.val());
            }
        });

        $('.js-address-zipcode').each(function() {
            var $zipcode = $(this);

            $zipcode.on('change.pickupLocationAutofill', function() {
                if($pickupLocation.val() === '') {
                    var $shippingZipcode = $('.js-address-zipcode:visible').last();
                    var shippingZipcode = $shippingZipcode.val();
                    $pickupLocation.val(shippingZipcode);
                    recievePickupLocations(shippingZipcode);
                }
            });
        });

        $('.js-pickup-update').each(function() {
            var $button = $(this);

            $button.on('click.pickupLocationUpdate', function(e) {
                var zipcode = $pickupLocation.val();
                recievePickupLocations(zipcode);
            });
        });
    } else if ($timeframe.length) {
        $('.js-address-zipcode, .js-address-number').each(function(i, element) {
            const $address = $(element);

            recieveTimeframes();

            $address.on('change.timeframeAutofill', function() {
                recieveTimeframes();
            });
        });
    }
});
