const $header = $('.js-header');

if(location.hash !== '') {
    try {
        const $target = $(location.hash);

        if($target.length === 1) {
            setTimeout(() => {
                const offset = $target.offset().top - $header.outerHeight() - 32;
                window.scrollTo({top: offset});
            }, 1);
        }
    } catch (e) {}
}

$('.js-anchor-link').each((i, element) => {
    const $link = $(element);
    const $target = $($link.attr('href'));

    if($target.length === 1) {
        $link.on('click', e => {
            e.preventDefault();

            const offset = $target.offset().top - 16;
            window.scrollTo({behavior: 'smooth', top: offset});
        });
    }
});
