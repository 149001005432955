import zilvercms from "./_utils";

if(zilvercms.viewport.isEqualOrSmallerThan('md')) {
    const textElements = [...document.querySelectorAll('.js-text')];

    textElements.map(text => {
        const content = text.querySelector('.js-text-content');
        const link = text.querySelector('.js-text-link');

        const contentStyle = window.getComputedStyle(content);
        const lineHeight = parseFloat(contentStyle.getPropertyValue('line-height'));
        const lines = parseInt(text.dataset.lines);

        const toggleContent = () => {
            if(content.clientHeight === (lineHeight * lines)) {
                text.removeAttribute('data-toggled');
                content.style.height = `${content.scrollHeight}px`;
            } else {
                content.style.height = `${lineHeight * lines}px`;
                text.setAttribute('data-toggled', '');
            }
        };

        if(content.scrollHeight <= (lineHeight * lines)) {
            link.style.display = 'none';
        } else {
            toggleContent();
            link.addEventListener('click', toggleContent);
        }
    });

}
