$('.js-images-popup').each((i, element) => {
    const $imagesPopup = $(element);
    const $imagesTrack = $imagesPopup.find('.js-images-popup-track');

    const $images = $imagesPopup.find('.js-images-popup-image');

    const $thumbsTrack = $imagesPopup.find('.js-images-popup-thumbs-track');
    const $thumbs = $thumbsTrack.find('.js-images-popup-thumb');

    const $arrowPrev = $imagesPopup.find('.js-images-popup-prev');
    const $arrowNext = $imagesPopup.find('.js-images-popup-next');

    $('body').on('click', `.js-images-popup-trigger[data-popup="${element.dataset.popup}"]`, e => {
        const index = e.currentTarget.dataset.index || 0;

        $imagesTrack.slick('slickGoTo', index, true);

        window[`popup.open.${element.dataset.popup}`]();
    });

    document.body.addEventListener(`popup.close.${element.dataset.popup}`, () => {
        const slick = $imagesTrack.slick('getSlick');
        const currentSlide = slick.currentSlide;
        const videoIframe = $(slick.$slides[currentSlide]).find('iframe');

        $images.removeAttr('data-zoom');

        if(videoIframe.length === 1) {
            videoIframe[0].contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
        }
    });

    $thumbs.on('click', e => {
        const index = e.currentTarget.dataset.index;

        $imagesTrack.slick('slickGoTo', index);
    });

    $imagesTrack.on('beforeChange', (event, slick, currentSlide) => {
        const videoIframe = $(slick.$slides[currentSlide]).find('iframe');

        $images.removeAttr('data-zoom');

        if(videoIframe.length === 1) {
            videoIframe[0].contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*')
        }
    });

    $imagesTrack.slick({
        infinite: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        prevArrow: $arrowPrev,
        nextArrow: $arrowNext,
        asNavFor: '.js-images-popup-thumbs-track'
    });

    $thumbsTrack.slick({
        infinite: true,
        arrows: false,
        variableWidth: true,
        slidesToShow: 12,
        slidesToScroll: 1,
        swipeToSlide: true,
        asNavFor: '.js-images-popup-track',
        dots: false,
        focusOnSelect: false
    });

    $images.each(i => {
        const image = $images[i];
        const imageSize = image.querySelector('.js-images-popup-image-size');
        const picture = image.querySelector('picture');
        const imageElement = picture.querySelector('img');

        let isDragging = false;
        let dragPosition = {
            left: 0,
            top: 0,
            x: 0,
            y: 0
        };

        if(imageElement.naturalWidth > picture.clientWidth || imageElement.naturalHeight > picture.clientHeight) {
            image.addEventListener('click', e => {
                if(image.hasAttribute('data-zoom')) {
                    if(!isDragging) {
                        imageSize.style.removeProperty('width');
                        imageSize.style.removeProperty('height');
                        picture.style.removeProperty('width');
                        picture.style.removeProperty('height');

                        image.scrollTo({top: picture.clientHeight / 2, left: picture.clientWidth / 2, behavior: 'smooth'});

                        setTimeout(() => {
                            image.removeAttribute('data-zoom');
                        }, 200);

                        $imagesTrack.slick('slickSetOption', 'swipe', true);
                    }
                } else {
                    image.setAttribute('data-zoom', '');
                    $imagesTrack.slick('slickSetOption', 'swipe', false);

                    picture.style.transition = 'none';
                    picture.style.width = `${picture.clientWidth}px`;
                    picture.style.height = `${picture.clientHeight}px`;

                    imageSize.style.width = `${imageElement.naturalWidth}px`;
                    imageSize.style.height = `${imageElement.naturalHeight}px`;

                    setTimeout(() => {
                        picture.style.removeProperty('transition');
                        picture.scrollIntoView({block: 'center', inline: 'center'});

                        setTimeout(() => {
                            const bounds = picture.getBoundingClientRect();
                            image.scrollTo({top: e.clientY - bounds.top, left: e.clientX - bounds.left, behavior: 'smooth'});

                            picture.style.width = `${imageElement.naturalWidth}px`;
                            picture.style.height = `${imageElement.naturalHeight}px`;
                        }, 10)
                    }, 10);
                }
            });
        } else {
            image.removeAttribute('data-with-zoom')
        }

        const mouseDownHandler = e => {
            e.preventDefault();

            dragPosition = {
                left: image.scrollLeft,
                top: image.scrollTop,
                x: e.clientX,
                y: e.clientY
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };

        const mouseMoveHandler = e => {
            isDragging = true;
            image.style.cursor = 'move';

            image.scrollLeft = dragPosition.left - (e.clientX - dragPosition.x);
            image.scrollTop = dragPosition.top - (e.clientY - dragPosition.y);
        };

        const mouseUpHandler = () => {
            image.style.removeProperty('cursor');

            setTimeout(() => isDragging = false);

            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        };

        image.addEventListener('mousedown', mouseDownHandler);
    });
})
