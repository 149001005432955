const paginations = document.getElementsByClassName('js-pagination');

Object.values(paginations).map(pagination => {
    const itemsContainer = pagination.getElementsByClassName('js-pagination-items')[0];
    const items = Object.values(itemsContainer.children);

    const navPrev = pagination.getElementsByClassName('js-pagination-nav-prev')[0];
    const navNext = pagination.getElementsByClassName('js-pagination-nav-next')[0];
    const navItems = Object.values(pagination.getElementsByClassName('js-pagination-nav-item'));

    const navPrevSeparator = pagination.getElementsByClassName('js-pagination-nav-prev-separator')[0];
    const navNextSeparator = pagination.getElementsByClassName('js-pagination-nav-next-separator')[0];

    const pages = parseInt(pagination.dataset.pages);
    const itemsPerPage = parseInt(pagination.dataset.itemsPerPage);
    let currentPage = 1;

    const setPageContent = page => {
        const endIndex = (page * itemsPerPage);
        const startIndex = endIndex - itemsPerPage;

        items.map((item, index) => {
            if(index >= startIndex && index < endIndex) {
                item.style.removeProperty('display');
            } else {
                item.style.display = 'none';
            }
        });
    };

    const updateNavItems = page => {
        navPrev.style.display = page === 1 ? 'none' : 'block';
        navNext.style.display = page === pages ? 'none' : 'block';

        navPrevSeparator.style.display = page > 3 ? 'block' : 'none';
        navNextSeparator.style.display = page < (pages - 2) ? 'block' : 'none';

        navItems.map(navItem => {
            const navItemPage = parseInt(navItem.dataset.page);

            if(navItemPage === page) {
                navItem.classList.add('pagination__nav-item--current');
            } else {
                navItem.classList.remove('pagination__nav-item--current');
            }

            if([1, pages].includes(navItemPage) || (navItemPage > (page - 2) && navItemPage < (page + 2))) {
                navItem.style.display = 'block';
            } else {
                navItem.style.display = 'none';
            }
        });
    };

    const goToPage = page => {
        currentPage = parseInt(page);

        setPageContent(currentPage);
        updateNavItems(currentPage);

        const paginationItemsBounds = itemsContainer.getBoundingClientRect();
        const offsetTop = paginationItemsBounds.top - 250;

        window.scrollBy({behavior: 'smooth', top: offsetTop});
    };

    navItems.map(navItem => navItem.addEventListener('click', () => {
        goToPage(navItem.dataset.page);
    }));

    navPrev.addEventListener('click', () => {
        if(currentPage > 1) {
            goToPage(currentPage - 1);
        }
    });

    navNext.addEventListener('click', () => {
        if(currentPage < pages) {
            goToPage(currentPage + 1);
        }
    });

    updateNavItems(currentPage);

    setTimeout(() => setPageContent(currentPage));
});
