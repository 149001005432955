import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

// IMPORT FUNCTION
const importAll = (r) => r.keys().forEach(r);

// INCLUDING DEPENDENCIES
require('jquery');
require('jquery-validation');
require('slick-carousel');
require('select2');

// SETTING REAL VIEW-HEIGHT
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

// INCLUDING SCRIPTS
const scripts = require.context('./scripts', true, /^.*\.js$/);

$(document).ready(() => importAll(scripts));

// INCLUDING IMAGES
const images = require.context('./images', true,  /\.(png|jpe?g|gif|svg)$/);
importAll(images);
