$('.js-sidebar-nav-children').each((i, element) => {
    let childrenList = element;

    if(childrenList.childElementCount > 10) {
        childrenList.classList.add('sidebar-nav--collapsed');

        $('<span class="sidebar-nav__toggle js-sidebar-nav-children-toggle">Toon meer</span>').insertAfter($(element));

        $('body').on('click', '.js-sidebar-nav-children-toggle', function() {
            $(this).prev().toggleClass('sidebar-nav--collapsed');
            $(this).toggleClass('sidebar-nav__toggle--open');

            $(this).text($(this).text() === 'Toon meer' ? 'Toon minder' :  'Toon meer');
        });
    }
});
