import axios from "axios";

const newsItems = document.querySelector('.js-newsitems');

if(![null, undefined, false, ''].includes(newsItems)) {
    const moreItems = newsItems.querySelector('.js-newsitems-more');

    if(![null, undefined, false, ''].includes(moreItems)) {
        const newsItemsItems = newsItems.querySelector('.js-newsitems-items');
        const moreItemsContainer = newsItems.querySelector('.js-newsitems-more-container');

        const pages = parseInt(newsItems.dataset.pages);
        let currentPage = 2;

        const fetchNextPageItems = async () => {
            if(currentPage < pages) {
                try {
                    let response = await axios.get(`${window.location.pathname}?page=${currentPage}`);

                    if(response.status === 200) {
                        const dom = document.createElement('div');
                        dom.innerHTML = response.data;

                        const pageItems = dom.querySelector('.js-newsitems-items').children;

                        [...pageItems].map(item => newsItemsItems.appendChild(item));

                        currentPage++;

                        if(currentPage === pages) {
                            moreItemsContainer.remove();
                        }
                    }
                } catch (e) {}
            } else {
                moreItemsContainer.remove();
            }
        }

        moreItems.addEventListener('click', fetchNextPageItems);
    }
}
