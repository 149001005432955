import React from 'react';
import ReactDOM from 'react-dom';
import ShopReviews from "../components/ShopReviews";

const shopReviewsContainer = document.querySelector('.js-shop-reviews');

if(![null, undefined].includes(shopReviewsContainer)) {
    ReactDOM.render(
        <ShopReviews id={shopReviewsContainer.dataset.id}
                     image={shopReviewsContainer.dataset.image}
                     background={shopReviewsContainer.dataset.background}
                     average={shopReviewsContainer.dataset.average}
                     amountPerPoints={shopReviewsContainer.dataset.amountPerPoints}
        />,
        shopReviewsContainer
    );
}
