import zilvercms from "./_utils";

const imageZoomResult = document.querySelector('.js-product-image-zoom-result');

if(![null, undefined].includes(imageZoomResult) && zilvercms.viewport.isEqualOrGreaterThan('lg')) {
    const images = [...document.querySelectorAll('.js-product-image-zoom')];

    images.map(image => {
        const imageFullUrl = image.dataset.full;
        const imageElement = image.querySelector('img');
        const imageZoomLens = image.querySelector('.js-product-image-zoom-lens');

        const ratioX = imageZoomResult.offsetWidth / imageZoomLens.offsetWidth;
        const ratioY = imageZoomResult.offsetHeight / imageZoomLens.offsetHeight;

        const mouseEnterHandler = () => {
            const currentResultBackgroundImage = imageZoomResult.style.backgroundImage;
            const resultBackgroundImage = `url("${imageFullUrl}")`;

            if(currentResultBackgroundImage !== resultBackgroundImage) {
                imageZoomResult.style.removeProperty('background-image');
                imageZoomResult.setAttribute('data-loading', '');

                const image = new Image();
                image.src = imageFullUrl;
                image.addEventListener('load', () => {
                    imageZoomResult.style.backgroundImage = resultBackgroundImage;
                    imageZoomResult.style.backgroundSize = `${imageElement.width * ratioX}px ${imageElement.height * ratioY}px`;

                    imageZoomResult.removeAttribute('data-loading');
                });
            }

            imageZoomLens.setAttribute('data-visible', '');
            imageZoomResult.setAttribute('data-visible', '');
        };

        const mouseMoveHandler = e => {
            e.preventDefault();

            if(!imageZoomLens.hasAttribute('data-visible') || !imageZoomResult.hasAttribute('data-visible')) {
                mouseEnterHandler();
            }

            const imageBounds = imageElement.getBoundingClientRect();

            const positionX = e.pageX - imageBounds.left - window.pageXOffset;
            const positionY = e.pageY - imageBounds.top - window.pageYOffset;

            let x = positionX - (imageZoomLens.offsetWidth / 2);
            let y = positionY - (imageZoomLens.offsetHeight / 2);

            if(x > imageElement.width - imageZoomLens.offsetWidth) {
                x = imageElement.width - imageZoomLens.offsetWidth;
            }

            if(x < 0) {
                x = 0;
            }

            if(y > imageElement.height - imageZoomLens.offsetHeight) {
                y = imageElement.height - imageZoomLens.offsetHeight;
            }

            if(y < 0) {
                y = 0;
            }

            imageZoomLens.style.left = `${x}px`;
            imageZoomLens.style.top = `${y}px`;

            imageZoomResult.style.backgroundPosition = `-${x * ratioX}px -${y * ratioY}px`;
        };

        const mouseLeaveHandler = () => {
            imageZoomLens.removeAttribute('data-visible');
            imageZoomResult.removeAttribute('data-visible');
        };

        image.addEventListener('mouseenter', mouseEnterHandler);
        image.addEventListener('mousemove', mouseMoveHandler);
        image.addEventListener('mouseleave', mouseLeaveHandler);

        imageZoomLens.addEventListener('mousemove', mouseMoveHandler);
    });
}
