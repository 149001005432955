import axios from 'axios';
import customer from "./_customer";
import zilvercms from "./_utils";
import {addPaymentInfo} from "./_datalayer";
import * as datalayer from "./_datalayer";

Object.values(document.getElementsByClassName('js-checkout')).map(checkout => {
    // Toggle business fields
    const isBusinessToggle = checkout.getElementsByClassName('js-checkout-business-toggle')[0];
    const cocField = checkout.querySelector('.js-checkout-coc');
    const termsLink = [...checkout.getElementsByClassName('js-terms-link')];
    const businessFields = Object.values(checkout.getElementsByClassName('js-checkout-business'));
    const AddressCountry = Object.values(checkout.getElementsByClassName('js-address-country'));
    const vatField = checkout.getElementsByClassName('js-checkout-vat')[0];
    const vatLabelField = checkout.getElementsByClassName('js-checkout-vat-label')[0];
    const billinkExtraOptions = Object.values(checkout.getElementsByClassName('js-billink-options'));
    const birthdateGhosts = [...checkout.querySelectorAll('.js-checkout-birthdate-ghost')]
    const birthdateField = checkout.querySelector('.js-checkout-birthdate-field');
    const paymentMethods = [...checkout.querySelectorAll('.js-checkout-payment-method')];
    const checkoutLabel = [...checkout.querySelectorAll('.js-checkout-label')];
    const checkoutTerms = [...checkout.querySelectorAll('.js-checkout-terms')];

    const checkPaymentOrderMinMax = () => {

        const paymentMethodMinMax =  [...checkout.querySelectorAll('.js-payment-method-minmax')];
        if(paymentMethodMinMax.length > 0) {
            paymentMethodMinMax.map(paymentMethod => {
                const B2CMin = parseInt(paymentMethod.dataset.orderminb2c);
                const B2CMax =  parseInt(paymentMethod.dataset.ordermaxb2c);
                const B2BMin =  parseInt(paymentMethod.dataset.orderminb2b);
                const B2BMax =  parseInt(paymentMethod.dataset.ordermaxb2b);
                const orderTotal =  parseInt(paymentMethod.dataset.ordertotal);
                paymentMethod.classList.add('d-none');

                if(isBusinessToggle.checked) {
                    if(orderTotal >= B2BMin && orderTotal <= B2BMax){
                        paymentMethod.classList.remove('d-none');
                    }
                }else {
                    if(orderTotal >= B2CMin && orderTotal <= B2CMax){
                        paymentMethod.classList.remove('d-none');
                    }
                }
            })
        }
    }

    const isBusinessToggleHandler = () => {
       businessFields.map(businessField => {
           if(isBusinessToggle.checked) {
               businessField.classList.remove('d-none');
               termsLink.map(link => {
                   link.href = link.getAttribute('data-business');
               });

               billinkExtraOptions.map(billinkExtraOption => {
                   billinkExtraOption.classList.add('d-none');
               });

               birthdateField.value = '1970-01-01';

               if(checkout.getElementsByClassName('js-address-country')[0].value === 'be') {
                   vatField.required = true;
                   vatLabelField.querySelector('.input__label--optional').classList.add('d-none');
               }else {
                   vatField.required = false;
                   vatLabelField.querySelector('.input__label--optional').classList.remove('d-none');
               }
           } else {
               businessField.classList.add('d-none');
               termsLink.map(link => {
                   link.href = link.getAttribute('data-private');
               });

               billinkExtraOptions.map(billinkExtraOption => {
                   billinkExtraOption.classList.remove('d-none');
               });

               if(birthdateField) {
                   birthdateField.value = '';
               }
               vatField.required = false;

               vatLabelField.querySelector('.input__label--optional').classList.remove('d-none');
           }
       });

       paymentMethods.filter(paymentMethod => paymentMethod.checked).map(paymentMethod => {
           if(paymentMethod.dataset.method === 'billink' && isBusinessToggle.checked) {
               cocField.setAttribute('required', '');
           } else {
               cocField.removeAttribute('required');
           }
       });

        checkPaymentOrderMinMax();
    };

    isBusinessToggleHandler();
    isBusinessToggle.addEventListener('change', isBusinessToggleHandler);

    // Birthdate ghosts
    if(birthdateGhosts.length > 0 && ![null, undefined].includes(birthdateField)) {
        birthdateGhosts.map(birthdateGhost => {
            birthdateGhost.value = birthdateField.value;

            birthdateGhost.addEventListener('change', () => birthdateField.value = birthdateGhost.value);
        });
    }

    const paymentMethodChangeHandler = e => {
        if(e.currentTarget.dataset.method === 'billink' && isBusinessToggle.checked) {
            cocField.setAttribute('required', '');
        } else {
            cocField.removeAttribute('required');
        }
        if(e.currentTarget.dataset.method == 'billink' || e.currentTarget.dataset.method == '1717'){
            $(checkoutLabel).each(function(index, value) {
                value.innerText = zilvercms.translationHelper('checkout.submit.text-alt');
            });
            $(checkoutTerms).each(function(index, value) {
                value.innerText = value.innerText.replace(zilvercms.translationHelper('checkout.submit.text'),zilvercms.translationHelper('checkout.submit.text-alt'));
            });
        }
        else{
            $(checkoutLabel).each(function(index, value) {
                value.innerText = zilvercms.translationHelper('checkout.submit.text');
            });
            $(checkoutTerms).each(function(index, value) {
                value.innerText = value.innerText.replace(zilvercms.translationHelper('checkout.submit.text-alt'),zilvercms.translationHelper('checkout.submit.text'));
            });
        }

        datalayer.addPaymentInfo(e.currentTarget.dataset.method, window.items);
    }

    paymentMethods.map(paymentMethod => paymentMethod.addEventListener('change', paymentMethodChangeHandler));

    checkout.addEventListener('click', function(event){
        let submitBtn = checkout.querySelectorAll('button[type="submit"]'),
            errorsSummary = document.querySelector('.js-errors-summary');

        if(event.target.matches('button[type="submit"]')) {
            Object.values(submitBtn).map(() => {
                if(document.querySelector('.js-errors-summary-list').childNodes.length >= 1) {
                    errorsSummary.style.display = 'block';
                } else {
                    errorsSummary.style.display = 'none';
                }
            });
        }
    });

    // Disabled submit button on form submit to prevent double submits
    checkout.addEventListener('submit', function(){
        let submitBtn = checkout.querySelectorAll('button[type="submit"]');

        Object.values(submitBtn).map(btn => {
            btn.setAttribute('disabled', 'disabled');
        });
    });

    // Toggle login fields
    const loginFields = checkout.querySelector('.js-checkout-login');
    const loginShow = checkout.querySelector('.js-checkout-login-show');

    const loginEmail = checkout.querySelector('.js-checkout-login-email');
    const loginPassword = checkout.querySelector('.js-checkout-login-password');
    const loginSubmit = checkout.querySelector('.js-checkout-login-submit');
    const loginError = checkout.querySelector('.js-checkout-login-error');

    if(![loginShow, loginFields].includes(null)) {
        loginShow.addEventListener('click', () => {
            loginPassword.setAttribute('required', '');
            loginPassword.dispatchEvent(new Event('change'));

            loginFields.classList.remove('d-none');
            loginShow.parentElement.remove();
        });

        loginPassword.addEventListener('keypress', e => {
            if(e.key === 'Enter') {
                e.preventDefault();

                loginSubmit.dispatchEvent(new Event('click'));
            }
        })

        loginSubmit.addEventListener('click', async () => {
            const formData = new FormData();
            formData.append('email_address', loginEmail.value);
            formData.append('password', loginPassword.value);

            let login = await customer.login(formData);

            if(login === true) {
                window.location.reload();
            } else {
                loginError.innerText = login;
                loginError.classList.remove('d-none');
            }
        });
    }

    // Toggle register fields
    const wantsToRegisterToggle = checkout.getElementsByClassName('js-checkout-register-toggle')[0];
    const registerFields = Object.values(checkout.getElementsByClassName('js-checkout-register'));

    if(wantsToRegisterToggle !== undefined) {
        wantsToRegisterToggle.addEventListener('change', () => registerFields.map(registerField => registerField.classList.toggle('d-none')));
    }

    // Toggle shipping address
    const toggleShippingAddress = checkout.querySelector('.js-checkout-address-sameaddress');
    const shippingAddress = checkout.querySelector('.js-checkout-address[data-type="shipping"]');

    const toggleShippingAddressHandler = () => {
        const zipcodeField = shippingAddress.querySelector('.js-address-zipcode');
        const numberField = shippingAddress.querySelector('.js-address-number');
        const addressField = shippingAddress.querySelector('.js-address-address');
        const placeField = shippingAddress.querySelector('.js-address-place');
        const firstnameField = shippingAddress.querySelector('.js-shipping-firstname');
        const lastnameField = shippingAddress.querySelector('.js-shipping-lastname');

        if(toggleShippingAddress.checked) {
            shippingAddress.classList.add('d-none');
            zipcodeField.removeAttribute('required');
            numberField.removeAttribute('required');
            addressField.removeAttribute('required');
            placeField.removeAttribute('required');
            firstnameField.removeAttribute('required');
            lastnameField.removeAttribute('required');
        } else {
            shippingAddress.classList.remove('d-none');
            zipcodeField.setAttribute('required', '');
            numberField.setAttribute('required', '');
            addressField.setAttribute('required', '');
            placeField.setAttribute('required', '');
            firstnameField.setAttribute('required', '');
            lastnameField.setAttribute('required', '');
        }
    };

    toggleShippingAddressHandler();
    toggleShippingAddress.addEventListener('change', toggleShippingAddressHandler);

    checkPaymentOrderMinMax();
});
