import axios from "axios";

const cartItemCounts = [...document.querySelectorAll('.js-cart-item-count')];

const fetchCartItemCount = async () => {
    let response = await axios.get('/bjax/webshop/Getobjectasjson/cart/');

    if(response.status === 200) {
        cartItemCounts.map(cartItemCount => cartItemCount.setAttribute('data-count', response.data.itemcount))
    }
}

if(cartItemCounts.length > 0) {
    fetchCartItemCount();
}
