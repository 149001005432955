import customer from "./_customer";
import zilvercms from "./_utils";

// Customer login page
const customerLoginForm = document.getElementsByClassName('js-customer-login')[0];

if(customerLoginForm !== undefined) {
    customerLoginForm.addEventListener('submit', async e => {
        e.preventDefault();

        const loginErrorMessage = customerLoginForm.getElementsByClassName('js-customer-login-error')[0];
        loginErrorMessage.classList.add('d-none');

        const formData = new FormData(customerLoginForm);

        let response = await customer.login(formData);

        if(response === true) {
            window.location.href = '/Customer/';
        } else {
            loginErrorMessage.innerText = response;
            loginErrorMessage.classList.remove('d-none');
        }
    });
}

// Customer register page
const customerRegisterForm = document.getElementsByClassName('js-customer-register')[0];

if(customerRegisterForm !== undefined) {
    const password = customerRegisterForm.querySelector('.js-customer-register-password');
    const passwordRepeat = customerRegisterForm.querySelector('.js-customer-register-password-repeat');

    passwordRepeat.addEventListener('input', () => {
        if(passwordRepeat.value !== password.value) {
            passwordRepeat.setCustomValidity(zilvercms.translationHelper('customer.password.not-equal'));
        } else {
            passwordRepeat.setCustomValidity('');
        }
    });

    password.addEventListener('input', () => {
        if(customer.validatePasswordStrength(password.value) < 2) {
            password.setCustomValidity(zilvercms.translationHelper('customer.password.not-strong'));
        }
        else{
            password.setCustomValidity('');
        }
    });

    customerRegisterForm.addEventListener('submit', async e => {
        e.preventDefault();

        const registerErrorMessage = customerRegisterForm.getElementsByClassName('js-customer-register-error')[0];
        registerErrorMessage.classList.add('d-none');

        if(password.value !== passwordRepeat.value) {
            passwordRepeat.parentElement.setAttribute('data-error', zilvercms.translationHelper('customer.password.not-equal'));

            return false;
        }

        if(customer.validatePasswordStrength(password.value) < 2) {
            password.parentElement.setAttribute('data-error', zilvercms.translationHelper('customer.password.not-strong'));

            return false;
        }

        const formData = new FormData(customerRegisterForm);

        let response = await customer.register(formData);

        if(response === true) {
            window.location.href = '/Customer/';
        } else {
            registerErrorMessage.innerHTML = response;
            registerErrorMessage.classList.remove('d-none');
        }
    });
}

// Logout triggers
Object.values(document.getElementsByClassName('js-customer-logout')).map(logoutTrigger => {
    logoutTrigger.addEventListener('click', async () => {
        await customer.logout();
    })
})
