import validate from "./_validate";
var errorsArray = [];
const checkout = document.querySelector('.js-checkout');

[...document.querySelectorAll('.js-input')].map(input => {
    const group = input.querySelector('.js-input-group');
    const field = input.querySelector('.js-input-field');

    const fieldChangeHandler = (init = false) => {
        const [isValid, errors] = validate(field);
        const isRequired = field.hasAttribute('required');
        const hasValue = field.value !== '';
        let errorsSummary = document.querySelector('.js-errors-summary'),
            errorsSummaryList = document.querySelector('.js-errors-summary-list');

        if(isValid && (isRequired || (!isRequired && hasValue))) {
            group.setAttribute('data-valid', '');
        } else {
            group.removeAttribute('data-valid');
        }

        if(field.value !== '') {
            group.removeAttribute('data-not-used');
        }

        if(errors.length > 0 && (isRequired || (!isRequired && hasValue))) {
            if(init !== true) {
                input.setAttribute('data-error', errors[0]);

                if(!isValid) {
                    addToErrorSummary(errors[0]);
                }
            }
        } else {
            input.removeAttribute('data-error');

            if(errorsArray.indexOf(field.name) !== -1) {
                errorsArray.splice(errorsArray.indexOf(field.name), 1);
                document.querySelector('[data-id="' + field.name + '"]').remove();
            }
        }

        if(errorsSummaryList && errorsSummaryList.childNodes.length < 1) {
            errorsSummary.style.display = 'none';
        }
    };

    const addToErrorSummary = (error) => {
        field.setAttribute('id', field.name);

        let errorList = document.querySelector('.js-errors-summary-list'),
            newError = document.createElement('a');

        if(errorsArray.indexOf(field.name) === -1) {
            if(field.name !== 'data[invoice][street]' && field.name !== 'data[invoice][city]') {
                errorsArray.push(field.name);

                newError.setAttribute('href', '#' + field.name);
                newError.setAttribute('data-id', field.name);
                newError.appendChild(document.createTextNode(error));

                if(errorList) {
                    errorList.appendChild(newError);
                }
            }
        }
    }

    const fieldTouchedHandler = () => {
        input.setAttribute('data-touched', '');

        field.removeEventListener('focus', fieldTouchedHandler);
    }

    const forceValidation = () => {
        input.setAttribute('data-touched', '');
    }

    field.addEventListener('focus', fieldTouchedHandler);

    fieldChangeHandler(true);
    field.addEventListener('keyup', fieldChangeHandler);
    field.addEventListener('change', fieldChangeHandler);

    if(checkout) {
        const countrySelectors = checkout.querySelectorAll('.js-address-country');
        const sameAddress = checkout.querySelector('.js-checkout-address-sameaddress');

        sameAddress.addEventListener('change', fieldChangeHandler);
        Object.values(checkout.querySelectorAll('button[type="submit"]')).map(button => button.addEventListener("click", forceValidation))
        Object.values(countrySelectors).map(countrySelector => countrySelector.addEventListener("click", fieldChangeHandler))
    } else {
        const countrySelectors = document.querySelectorAll('.js-address-country');
        if(countrySelectors) {
            Object.values(countrySelectors).map(countrySelector => countrySelector.addEventListener("click", fieldChangeHandler))
        }
    }
});
