import React, {useEffect, useRef, useState} from 'react';
import zilvercms from "../scripts/_utils";
import ReviewStars from "./ReviewStars";
import PopupAddReview from "./PopupAddReview";
import axios from "axios";
import Review from "./Review";

const ShopReviews = props => {
    const [reviews, setReviews] = useState({});
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const [isLoading, setIsLoading] = useState(false);

    const [showPopup, setShowPopup] = useState(false);
    const [showReviewBars, setShowReviewBars] = useState(false);

    const reviewsContent = useRef(null);

    useEffect(() => {
        setShowReviewBars(true);

        setIsLoading(true);
        fetchReviewsPage(page);
        setIsLoading(false);
    }, []);

    const fetchReviewsPage = async offset => {
        let response = await axios.get(`/bjax/Product/Reviews/?productId=${props.id}&page=${offset}&resultsperpage=10`);

        if(response.status === 200) {
            const newReviews = {...reviews};
            newReviews[offset] = parseReviews(response.data.items);

            setReviews(newReviews);
            setTotal(response.data.total);
        }
    };

    const parseReviews = newReviews => {
        const parsedReviews = [];

        newReviews.map(review => {
            let content = review.review;
            let verified = false;

            if(content.startsWith('{')) {
                try {
                    const parsedContent = JSON.parse(content);
                    content = parsedContent.message;
                    verified = parseInt(parsedContent.recommends) === 1;
                } catch(e) {}
            }

            parsedReviews.push({
                name: review.name,
                date: review.date,
                points: review.points,
                review: content,
                verified,
            })
        });

        return parsedReviews;
    }

    const goToPage = async newPage => {
        setIsLoading(true);

        window.scrollTo({top: reviewsContent.current.offsetTop - 200, behavior: 'smooth'});

        if(!Object.keys(reviews).map(Number).includes(newPage)) {
            await fetchReviewsPage(newPage);
        }

        setPage(newPage);
        setIsLoading(false);
    }

    return (
        <div className="shop-reviews">
            <div className="shop-reviews__header">
                <div className="container">
                    <div className="row justify-content-between align-items-lg-center">
                        <div className="col-4 align-self-lg-end order-3 pl-0">
                            <picture className="shop-reviews__header-background img img--tl">
                                <img src={props.background} loading="lazy" />
                            </picture>

                            <picture className="shop-reviews__header-image img img--b">
                                <img src={props.image} width="" height={425} loading="lazy" />
                            </picture>
                        </div>

                        <div className="col-8 col-lg-auto col-xxl-3 py-lg-5 mt-3 my-lg-5 order-1 pr-0">
                            <h1 className="heading heading--xl mb-4 mb-lg-5">{zilvercms.translationHelper('webshop.reviews.heading')}</h1>

                            <h2 className="shop-reviews__average heading mb-4">{props.average}/5</h2>

                            <ReviewStars className="shop-reviews__average-stars" rating={props.average} average withLabel />

                            <div className="text mt-4 mb-3" dangerouslySetInnerHTML={{__html: zilvercms.translationHelper('webshop.reviews.based-on', {
                                '%reviews%': zilvercms.translationHelper(`webshop.reviews.rating${total === 1 ? '' : 's'}`),
                                '%amount%': total,
                            })}} />

                            {zilvercms.viewport.isEqualOrGreaterThan('lg') && (
                                <button type="button" className="btn btn--full" onClick={() => setShowPopup(true)}>
                                    {zilvercms.translationHelper('webshop.reviews.write')}

                                    <i>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 13">
                                            <path d="M8.33 6.775c-1.307-2.13-2.682-4.142-3.797-6H.114l3.797 6-3.797 6h4.42c1.325-2.076 2.65-4.226 3.796-6z" fill="#FFF" fillRule="nonzero"/>
                                        </svg>
                                    </i>
                                </button>
                            )}
                        </div>

                        <div className="col-12 col-lg-auto mt-4 order-5 order-lg-2 mb-4 mb-lg-0">
                            <div className="shop-reviews__bars text">
                                {props.amountPerPoints.split(',').map((percentage, index) => (
                                    <React.Fragment key={index + 1}>
                                        <strong className="text-right">{5 - index} {zilvercms.translationHelper(`webshop.reviews.star${5 - index === 1 ? '' : 's'}`)}</strong>

                                        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 27 28">
                                            <path d="M25.961 10.33l-7.713-1.18a.896.896 0 01-.676-.507l-3.417-7.3a.895.895 0 00-1.623 0l-3.417 7.3a.896.896 0 01-.676.506L.726 10.331a.9.9 0 00-.506 1.516l5.637 5.791a.9.9 0 01.243.773l-1.323 8.13a.897.897 0 001.318.931l6.815-3.777c.27-.15.597-.15.867 0l6.815 3.777a.897.897 0 001.318-.931l-1.323-8.13a.9.9 0 01.244-.773l5.636-5.791a.9.9 0 00-.506-1.516z" fillRule="nonzero"/>
                                        </svg>

                                        <div className="shop-reviews__bar">
                                            <span style={{width: showReviewBars ? `${percentage}%` : 0}} />
                                        </div>

                                        <span>{percentage}%</span>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>


                        {zilvercms.viewport.isEqualOrSmallerThan('md') && (
                            <div className="col-12 order-4">
                                <button type="button" className="btn btn--full" onClick={() => setShowPopup(true)}>
                                    {zilvercms.translationHelper('webshop.reviews.write')}

                                    <i>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 13">
                                            <path d="M8.33 6.775c-1.307-2.13-2.682-4.142-3.797-6H.114l3.797 6-3.797 6h4.42c1.325-2.076 2.65-4.226 3.796-6z" fill="#FFF" fillRule="nonzero"/>
                                        </svg>
                                    </i>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {![null, undefined].includes(reviews?.[page]) && (
                <div ref={reviewsContent} className="shop-reviews__content pt-4 pt-lg-5" data-loading={isLoading ? '' : null}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="shop-reviews__reviews">
                                    {reviews[page].map((review, index) => (
                                        <Review key={index + review.date} review={review} />
                                    ))}
                                </div>
                            </div>
                        </div>

                        {total > 10 && (
                            <div className="row">
                                <div className="col-12">
                                    <div className="pagination">
                                        <div className="pagination__nav">
                                            {page > 1 && (
                                                <li className="pagination__nav-item" onClick={() => goToPage(page - 1)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10">
                                                        <path d="M-.002 4.75C1.033 3.064 2.12 1.471 3.004 0h2.498L2.496 4.75 5.502 9.5H3.004C1.954 7.857.904 6.154-.002 4.75z" fill="#006DB5" fillRule="nonzero"/>
                                                    </svg>
                                                </li>
                                            )}

                                            {zilvercms.for(Math.ceil(total / 10))
                                                .filter(i => [1, Math.ceil(total / 10)].includes(i + 1) || ((i + 1) > (page - 2) && (i + 1) < (page + 2)))
                                                .map(i => (
                                                    <React.Fragment key={i}>
                                                        {(i + 1) === Math.ceil(total / 10) && page < (Math.ceil(total / 10) - 2) && (
                                                            <li className="pagination__nav-item pagination__nav-item--separator">
                                                                <span>…</span>
                                                            </li>
                                                        )}

                                                        <li className={`pagination__nav-item${(i + 1) === page ? ' pagination__nav-item--current' : ''}`} onClick={() => goToPage(i + 1)}>
                                                            <span>{i + 1}</span>
                                                        </li>

                                                        {(i + 1) === 1 && page > 3 && (
                                                            <li className="pagination__nav-item pagination__nav-item--separator">
                                                                <span>…</span>
                                                            </li>
                                                        )}
                                                    </React.Fragment>
                                                ))
                                            }

                                            {page !== Math.ceil(total / 10) && (
                                                <li className="pagination__nav-item" onClick={() => goToPage(page + 1)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1280 2133">
                                                        <path d="M106 .4c0 .3 144 228 320 506.1s320 506.1 320 506.7c0 .6-143.8 228.2-319.5 505.9-175.8 277.7-319.8 505.5-320.1 506.4-.6 1.3 27.4 1.5 266.3 1.5h266.9l39.9-62.7c81.9-129 126-198.8 272.8-432.3 147.5-234.5 231-366.4 297-469.2 16.9-26.3 30.7-48.6 30.7-49.7 0-2.3-44.2-74-103-167.1-54-85.5-102.5-161.3-203.7-318C797 254.7 735 156.8 655.7 26L640.2.5 373.1.2C226.2.1 106 .2 106 .4z"/>
                                                    </svg>
                                                </li>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {showPopup && (
                <PopupAddReview id={props.id} closePopup={() => setShowPopup(false)} />
            )}
        </div>
    );
};

export default ShopReviews;
