import React, {useRef, useState} from 'react';
import zilvercms from "../scripts/_utils";
import {ReviewStar} from "./ReviewStars";
import Input from "./Input";
import Toggle from "./Toggle";
import axios from "axios";

const PopupAddReview = props => {
    const [reviewComplete, setReviewComplete] = useState(false);

    const [starIsHovering, setStarIsHovering] = useState(false);
    const [starHoverIndex, setStarHoverIndex] = useState(0);
    const [starIndex, setStarIndex] = useState(0);

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [message, setMessage] = useState('');
    const [recommends, setRecommends] = useState('');

    const formElement = useRef(null);
    const reviewNumber = useRef(null);
    const reviewExpVal = useRef(null);

    const submitHandler = async e => {
        e.preventDefault();

        if(reviewExpVal.current.value === '') {
            reviewExpVal.current.value = reviewNumber.current.value;
        }

        const formData = new FormData(formElement.current);
        formData.append('review_name', `${firstname} ${lastname}`);
        formData.append('review_message', JSON.stringify({
            message,
            title: '',
            recommends: recommends ? 1 : 0,
            positive: '',
            negative: '',
        }));

        let response = await axios.post(`/productreview`, formData);

        if(response.status === 200) {
            setReviewComplete(true);
        }
    }

    return (
        <div className="popup popup--sm popup--open">
            <div className="popup__overlay" onClick={props.closePopup}/>

            <div className="popup__inner">
                <div className="popup__header">
                    <div className="row mx-n1 mx-lg-n3">
                        <div className="col align-self-center px-1 px-lg-3">
                            <h3 className="popup__heading heading">{zilvercms.translationHelper('webshop.reviews.write')}</h3>
                        </div>

                        <div className="col-auto px-1 px-lg-3">
                            <div className="popup__close" onClick={props.closePopup}>
                                <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15">
                                    <path d="M11.962.802L7 5.764 2.038.802l-2.02 2.02L4.98 7.784.018 12.746l2.02 2.02L7 9.804l4.962 4.962 2.02-2.02L9.02 7.784l4.962-4.962z" fill="#FFF" fillRule="nonzero"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="popup__content">
                    <form ref={formElement} method="post" onSubmit={submitHandler}>
                        <input type="hidden" name="product_id" value={props.id} />
                        <input ref={reviewNumber} type="hidden" name="review_number" value={Math.floor(Math.random() * 9) + 1} />
                        <input ref={reviewExpVal} type="hidden" name="review_expval" value="" />

                        {reviewComplete && (
                            <div className="message message--success mb-3">
                                {zilvercms.translationHelper('webshop.reviews.form.success')}
                            </div>
                        )}

                        <p className="text mt-n2 mb-3">{zilvercms.translationHelper('webshop.reviews.shop.description')}</p>

                        <div className="input">
                            <p className="input__label">{zilvercms.translationHelper('webshop.reviews.form.stars')} *</p>

                            <div className="stars" onMouseEnter={() => setStarIsHovering(true)} onMouseLeave={() => {setStarIsHovering(false); setStarHoverIndex(0)}}>
                                {[1,2,3,4,5].map(i => (
                                    <i key={i} className="star star--average star--lg mr-1" onClick={() => setStarIndex(i)} onMouseEnter={() => setStarHoverIndex(i)}>
                                        <ReviewStar token={i}
                                                    color={(starIsHovering && starHoverIndex >= i) ? '#ffcd00cc' : (starIndex >= i ? '#ffcd00' : '#d1d4d9')}
                                        />
                                    </i>
                                ))}

                                <span className="shop-reviews__rating-label">
                                    {zilvercms.translationHelper(`webshop.reviews.rating-labels.${starIsHovering ? starHoverIndex : starIndex}`)}
                                </span>
                            </div>

                            <input type="text"
                                   name="review_points"
                                   value={starIndex !== 0 ? starIndex : ''}
                                   required
                                   onChange={()=>{}}
                                   style={{pointerEvents: 'none', position: 'absolute', bottom: 0, opacity: 0}}
                            />
                        </div>

                        <div className="row">
                            <div className="col-12 col-lg-6 mt-4">
                                <Input label={zilvercms.translationHelper('form.firstname')}
                                       onChange={(name, value) => setFirstname(value)}
                                       validate
                                       required
                                />
                            </div>

                            <div className="col-12 col-lg-6 mt-4">
                                <Input label={zilvercms.translationHelper('form.lastname')}
                                       onChange={(name, value) => setLastname(value)}
                                       validate
                                       required
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 mt-4">
                                <Input type="textarea"
                                       label={zilvercms.translationHelper('webshop.reviews.form.review')}
                                       onChange={(name, value) => setMessage(value)}
                                       validate
                                       required
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12 mt-4">
                                <Toggle label={zilvercms.translationHelper('webshop.reviews.form.placed-order')}
                                        onChange={(name, value, checked) => setRecommends(checked)}
                                />
                            </div>
                        </div>

                        <div className="popup__footer mt-5">
                            <div className="row">
                                <div className="col-12 mt-4">
                                    {reviewComplete ? (
                                        <button type="button" className="btn btn--alt btn--full" onClick={props.closePopup}>
                                            {zilvercms.translationHelper('general.close')}

                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 13">
                                                    <path d="M8.33 6.775c-1.307-2.13-2.682-4.142-3.797-6H.114l3.797 6-3.797 6h4.42c1.325-2.076 2.65-4.226 3.796-6z" fill="#FFF" fillRule="nonzero"/>
                                                </svg>
                                            </i>
                                        </button>
                                    ) : (
                                        <button type="submit" className="btn btn--full">
                                            {zilvercms.translationHelper('webshop.reviews.form.submit')}

                                            <i>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 13">
                                                    <path d="M8.33 6.775c-1.307-2.13-2.682-4.142-3.797-6H.114l3.797 6-3.797 6h4.42c1.325-2.076 2.65-4.226 3.796-6z" fill="#FFF" fillRule="nonzero"/>
                                                </svg>
                                            </i>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default PopupAddReview;
