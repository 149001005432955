import axios from 'axios';

const wishlist = {
    id: null,
    productIds: []
};

wishlist.init = () => {
    if(![null, undefined, false, ''].includes(window.WISHLIST)) {
        wishlist.setId(window.WISHLIST.id);
        wishlist.setProductIds(window.WISHLIST.productIds);
    }
};

/**
 * @param {int} id
 * @returns {int|null}
 */
wishlist.setId = id => wishlist.id = id || null;

/**
 * @param {Array} productIds
 * @returns {Array}
 */
wishlist.setProductIds = productIds => wishlist.productIds = productIds || [];

/**
 * @param {int} productId
 */
wishlist.addProductId = productId => wishlist.productIds.push(productId);

/**
 * @param {int} productId
 * @returns {boolean}
 */
wishlist.containsProductId = productId => wishlist.productIds.includes(productId);

/**
 * @param {int} productId
 * @returns {Array}
 */
wishlist.removeProductId = productId => wishlist.productIds = wishlist.productIds.filter(id => id !== productId);

/**
 * @async
 * @param {int} productId
 * @returns {Promise<int|boolean<false>>}
 */
wishlist.addProduct = async productId => {
    if(!wishlist.containsProductId(productId)) {
        let response = await axios.post('/ajax/wishlist/addWishlistProduct/', {
            ...(wishlist.id !== null ? {wishlistId: wishlist.id} : {}),
            productId: productId,
        });

        if(response.status === 200) {
            if(wishlist.id === null) {
                wishlist.setId(response.data.wishListId);
            }

            wishlist.addProductId(productId);

            return wishlist.productIds.length;
        }

        return false;
    }

    return wishlist.removeProduct(productId);
};

/**
 * @async
 * @param productId
 * @returns {Promise<int|boolean<false>>}
 */
wishlist.removeProduct = async productId => {
    if(wishlist.containsProductId(productId) && wishlist.id !== null) {
        let response = await axios.post('/ajax/wishlist/removeProductFromWishlist/', {
            wishlistId: wishlist.id,
            productId: productId,
        });

        if(response.status === 200) {
            wishlist.removeProductId(productId);

            return wishlist.productIds.length;
        }

        return false;
    }

    return wishlist.addProduct(productId);
};

export default wishlist;
