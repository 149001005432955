import zilvercms from "./_utils";

if(zilvercms.viewport.isEqualOrSmallerThan('md')) {
    $('.js-homepage-banners').each((i, element) => {
        const $track = $(element).find('.js-homepage-banners-track');
        const $dots = $(element).find('.js-homepage-banners-dots');

        $track.slick({
            arrows: false,
            dots: true,
            appendDots: $dots,
            slidesToShow: 1,
            infinite: false,
            adaptiveHeight: true,
        });
    });
}