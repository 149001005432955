import zilvercms from "./_utils";

$('.js-timeline').each((i, element) => {
    const $timeline = $(element);
    const $container = $timeline.find('.js-timeline-container');
    const $items = $timeline.find('.js-timeline-item');
    const $footer = $timeline.find('.js-timeline-footer');
    const $more = $timeline.find('.js-timeline-more');

    const heightIncrement = zilvercms.viewport.isEqualOrGreaterThan('lg') ? 900 : 600;

    const setItemRowEnd = (item) => {
        item.style.removeProperty('grid-row-end');

        if(zilvercms.viewport.isEqualOrGreaterThan('lg')) {
            item.style.gridRowEnd = 'span '+ Math.ceil((item.scrollHeight / 10) + 2);
        } else {
            item.style.gridRowEnd = 'span '+ Math.ceil((item.scrollHeight / 5) + 2);
        }
    }

    const setItemSpans = () => {
        $items.each((i, element) => {
            const image = element.getElementsByClassName('js-timeline-image')[0];

            if(image !== undefined) {
                image.addEventListener('load', () => setItemRowEnd(element))
            }

            setItemRowEnd(element);
        });
    };

    const hideFooter = () => {
        $footer.hide();
        $timeline.addClass('timeline--without-footer');
    }

    const showMoreItems = (init = false) => {
        const currentHeight = $container[0].clientHeight;
        const scrollHeight = $container[0].scrollHeight;

        if(scrollHeight <= currentHeight) {
            $container.height(scrollHeight);
            hideFooter();
        }

        if(!init) {
            let newHeight = currentHeight + heightIncrement;

            if(newHeight > scrollHeight) {
                newHeight = scrollHeight;
                hideFooter();
            }
            $container.height(newHeight);
        }
    }

    $more.on('click', () => {
        showMoreItems();
        setItemSpans();
    });

    $(window).on('scroll', () => {
        const timelineBounds = $timeline[0].getBoundingClientRect();

        if((timelineBounds.top > 0 && timelineBounds.top < window.innerHeight) || (timelineBounds.bottom > 0 && timelineBounds.bottom < window.innerHeight)) {
            setItemSpans();
        }
    });

    setItemSpans();
    showMoreItems(true);

    $(window).on('resize', setItemSpans);
});
