const header = document.getElementsByClassName('js-header')[0];

if(header !== undefined) {
    let lastScrollPosition = 0;
    const scrollOffset = 10;

    const headerScrollHandler = () => {
        const windowTop = window.pageYOffset;
        const headerBounds = header.getBoundingClientRect();

        if(!document.body.classList.contains('nav--open')) {
            if(windowTop > (headerBounds.height * 2 + headerBounds.top)) {
                if(windowTop > (lastScrollPosition + scrollOffset)) {
                    header.setAttribute('data-hidden', '');
                    lastScrollPosition = windowTop;
                } else if(windowTop < (lastScrollPosition - scrollOffset)) {
                    header.removeAttribute('data-hidden');
                    lastScrollPosition = windowTop;
                }
            } else {
                header.removeAttribute('data-hidden');
                lastScrollPosition = windowTop;
            }
        }
    };

    window.addEventListener('scroll', headerScrollHandler);
}
