const videos = [...document.querySelectorAll('.js-video')];

if(videos.length > 0) {
    const videoClickHandler = target => {
        const videoId = target.getAttribute('data-id');

        if(typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
            setTimeout(() => {
                videoClickHandler(target);
            }, 1000);
        } else {
            target.parentElement.setAttribute('data-video', '');

            const player = new YT.Player(target.getAttribute('id'), {
                videoId,
                playerVars: {
                    autoplay: 1,
                    modestbranding: 1,
                    playsinline: 1,
                    rel: 0
                }
            });

            setTimeout(function() {
                player.playVideo();
            }, 1000);

            target.removeEventListener('click', e => videoClickHandler(target));
        }
    };

    videos.map(video => video.addEventListener('click', e => videoClickHandler(e.currentTarget)));

    if(typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/player_api';
        tag.async = true;
        tag.defer = true;
        document.body.appendChild(tag);
    }
}
