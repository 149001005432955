import axios from 'axios';

const customer = {};

/**
 * @async
 * @param formData
 * @returns {Promise<boolean<true>|string>}
 */
customer.login = async formData => {
    try {
        await axios.post('/bjax/Customer/login', formData);

        return true;
    } catch (e) {
        return e.response.data.error_message;
    }
};

/**
 * @async
 * @returns {Promise<void>}
 */
customer.logout = async () => {
    try {
        let response = await axios.post('/bjax/Customer/logout');

        if(response.status === 205) {
            window.location = '/Customer/'
        }
    } catch (e) {}
};

/**
 * @param {string} password
 * @returns {int}
 */
customer.validatePasswordStrength = password => {
    let strength = 0;

    if(password.length > 6) {
        strength += 1;
    }

    if(password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
        strength += 1;
    }

    if(password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) {
        strength += 1;
    }

    if(password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
        strength += 1;
    }

    return strength;
};

/**
 * @async
 * @param {FormData} formData
 * @returns {Promise<boolean<true>|string>}
 */
customer.register = async formData => {
    try {
        await axios.post('/bjax/Customer/RegisterWithEmail/individual', formData);

        return true;
    } catch (e) {
        const errors = Object.values(e.response.data.error_fields);

        const messages = errors.map(error => `<p>${error}</p>`);

        return messages.join('');
    }
};

/**
 * @async
 * @param {string} type
 * @param {FormData} formData
 * @returns {Promise<boolean<true>|string>}
 */
customer.registerAfterCheckout = async (type, formData) => {
    try {
        await axios.post(`/bjax/Customer/registerAfterCheckout/${type}`, formData);

        return true;
    } catch (e) {
        return e.response.data.error_message;
    }
};

export default customer;
