import React, {useState, useEffect, useRef} from 'react';
import zilvercms from "../scripts/_utils";

const Input = props => {
    const [value, setValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [hasBeenBlurred, setHasBeenBlurred] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [isInValid, setIsInvalid] = useState(false);
    const [errors, setErrors] = useState([]);
    const [showErrors, setShowErrors] = useState(false);

    const inputElement = useRef(null);
    const initialValue = useRef('');

    useEffect(() => {
        initialValue.current = props.value || '';

        inputElement.current.addEventListener('input.reportValidity', forceValidation);

        return () => inputElement.current.removeEventListener('input.reportValidity', forceValidation);
    }, []);

    useEffect(() => {
        setValue(props.value || '');
    }, [props.value]);

    useEffect(() => {
        const isNewValueValid = validate();

        if((value !== '' || isTouched || hasBeenBlurred) && ((isFocused && (hasBeenBlurred || initialValue.current !== '')) || !isFocused)) {
            setShowErrors(true);

            if(!isNewValueValid) {
                setIsInvalid(true);
            } else {
                setIsInvalid(false);
            }
        } else {
            setShowErrors(false);
            setIsInvalid(false);
        }
    }, [value, isFocused, isTouched, hasBeenBlurred]);

    const forceValidation = () => {
        setIsTouched(true);

        setTimeout(() => validate());
    };

    const validate = () => {
        const validity = inputElement.current.validity;
        const isValueValid = validity.valid;
        const validityErrors = [];
        const translationPrefix = 'form.errors.';

        for(let key in validity) {
            if(key === 'valid' || !validity[key]) {
                continue;
            }

            if(key === 'typeMismatch' && props.type === 'email') {
                validityErrors.push(zilvercms.translationHelper(`${translationPrefix}invalidEmail`));
            } else if(key === 'patternMismatch') {
                validityErrors.push(zilvercms.translationHelper(`${translationPrefix}patternMismatch`, {'%format%': props.title}));
            } else if(key === 'tooShort') {
                validityErrors.push(zilvercms.translationHelper(`${translationPrefix}tooShort`, {'%characters%': props.minLength}));
            } else if(key === 'tooLong') {
                validityErrors.push(zilvercms.translationHelper(`${translationPrefix}tooLong`, {'%characters%': props.maxLength}));
            } else if(key === 'customError') {
                validityErrors.push(inputElement.current.validationMessage);
            } else {
                validityErrors.push(zilvercms.translationHelper(`${translationPrefix}${key}`));
            }
        }

        setIsValid(isValueValid);
        setErrors(validityErrors);

        return isValueValid;
    };

    const onClickHandler = () => {
        setIsTouched(true);
    };

    const onFocusHandler = () => {
        setIsFocused(true);
    };

    const onChangeHandler = e => {
        const newValue = e.currentTarget.value;

        setValue(newValue);

        if(![null, undefined, false, ''].includes(props.onChange)) {
            props.onChange(props.name, newValue, e.currentTarget.validity.valid);
        }
    };

    const onBlurHandler = e => {
        setHasBeenBlurred(true);
        setIsFocused(false);

        if(![null, undefined, false, ''].includes(props.onBlur)) {
            props.onBlur(props.name, e.currentTarget.value, e.currentTarget.validity.valid);
        }
    };

    return (
        <label className={`input${props.type === 'textarea' ? ` input--textarea` : ''}${props.validate ? ' input--validate' : ''}`}>
            {props.label !== null && (
                <div className="input__label mb-1">
                    {props.label}

                    {props.required && (
                        <span className="input__required ml-1">*</span>
                    )}
                </div>
            )}

            <div className="input__group"
                 data-valid={isValid ? '' : null}
                 data-touched={isTouched ? '' : null}
                 data-error={showErrors && errors.length > 0 ? errors[0] : null}
            >
                {props.type !== 'textarea' ? (
                    <input ref={inputElement}
                           name={props.name}
                           type={props.type}
                           value={value}
                           pattern={props.pattern}
                           minLength={props.minLength}
                           maxLength={props.maxLength}
                           placeholder={props.placeholder || props.label}
                           required={props.required}
                           className="input__field"
                           onClick={onClickHandler}
                           onFocus={onFocusHandler}
                           onChange={onChangeHandler}
                           onBlur={onBlurHandler}
                    />
                ) : (
                    <textarea ref={inputElement}
                           name={props.name}
                           value={value}
                           placeholder={props.placeholder || props.label}
                           required={props.required}
                           className="input__field"
                           onClick={onClickHandler}
                           onFocus={onFocusHandler}
                           onChange={onChangeHandler}
                           onBlur={onBlurHandler}
                    />
                )}

                {(isValid && (value !== '' || isTouched) && !(value === '' && !props.required)) && (
                    <div className="input__icons">
                        <i className="input__icon input__icon--valid">
                            <svg width="12" height="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 11">
                                <path d="M13.2.19L5.934 7.454 2.133 3.653.5 5.285l5.434 5.435 8.898-8.898z" fill="#3C8935" fillRule="evenodd"/>
                            </svg>
                        </i>
                    </div>
                )}
            </div>
        </label>
    );
};

Input.defaultProps = {
    addon: null,
    label: null,
    type: 'text',
    value: '',
    pattern: null,
    patternExplanation: null,
    minLength: null,
    maxLength: null,
    placeholder: null,
    required: false,
    onChange: null,
    onBlur: null,
}

export default Input;
