import axios from 'axios';
import * as datalayer from "./_datalayer";

const addToCartForms = document.getElementsByClassName('js-add-to-cart-form');

Object.values(addToCartForms).map(addToCartForm => {
    const addToCartButton = addToCartForm.getElementsByClassName('js-add-to-cart-button')[0];
    const addToCartPopup = document.getElementsByClassName('js-add-to-cart-popup')[0];
    const cartItemCount = document.getElementsByClassName('js-cart-item-count')[0];
    const upsells = Object.values(addToCartForm.getElementsByClassName('js-productdetail-upsell'));

    let cartItems = {};

    const setLoadingState = (element, isLoading) => element.classList.toggle('btn--loading', isLoading);

    const addToCartHandler = async e => {
        e.preventDefault();
        setLoadingState(addToCartButton, true);

        let response = await axios.post('/cart/?ajax=true', new FormData(addToCartForm));

        if(response.status === 200) {
            cartItems = response.data.items;
            cartItemCount.setAttribute('data-count', cartItems.length);

            const preSelectedUpsellIds = upsells.filter(upsell => upsell.checked).map(upsell => upsell.dataset.id);
            const addToCartUpsells = Object.values(addToCartPopup.getElementsByClassName('js-add-to-cart-popup-upsell'));
            const addToCartSelectedUpsells = Object.values(addToCartPopup.getElementsByClassName('js-add-to-cart-popup-selected-upsell'));

            addToCartUpsells
                .filter(upsell => preSelectedUpsellIds.includes(upsell.dataset.id))
                .map(upsell => upsell.checked = true);

            addToCartSelectedUpsells
                .filter(upsell => preSelectedUpsellIds.includes(upsell.dataset.id))
                .map(upsell => upsell.classList.add('popup-add-to-cart__product--selected'));

            window['popup.open.productdetail.add-to-cart']();

            addToCartUpsells.map(upsell => upsell.addEventListener('change', async e => {
                const upsellId = upsell.dataset.id;
                const upsellData = JSON.parse(upsell.dataset.data);
                const formData = new FormData();

                setLoadingState(upsell.nextElementSibling, true);

                if(e.currentTarget.checked) {
                    formData.append('command', 'addtocart');
                    formData.append('productkey', upsellId);
                    formData.append('count', 1);

                    let upsellResponse = await axios.post('/cart/?ajax=true', formData);

                    if(upsellResponse.status === 200) {
                        cartItems = upsellResponse.data.items;
                        cartItemCount.setAttribute('data-count', cartItems.length);

                        addToCartSelectedUpsells
                            .find(selectedUpsell => selectedUpsell.dataset.id === upsellId)
                            .classList.add('popup-add-to-cart__product--selected');

                        datalayer.addToCartUpsell(upsellData);
                        setLoadingState(upsell.nextElementSibling, false);
                    }
                } else {
                    const cartItem = cartItems.find(item => item.productId === upsellId);

                    formData.append('command', 'updatecart');
                    formData.append('cartitem_count_'+ cartItem.cartId, cartItem.quantity - 1);

                    let upsellResponse = await axios.post('/cart/?ajax=true', formData);

                    if(upsellResponse.status === 200) {
                        cartItems = upsellResponse.data.items;
                        cartItemCount.setAttribute('data-count', cartItems.length);

                        addToCartSelectedUpsells
                            .find(selectedUpsell => selectedUpsell.dataset.id === upsellId)
                            .classList.remove('popup-add-to-cart__product--selected');


                        datalayer.addToCartUpsell(upsellData);
                        setLoadingState(upsell.nextElementSibling, false);
                    }
                }
            }));

            datalayer.addToCart();
            setLoadingState(addToCartButton, false);
        }
    };

    addToCartForm.addEventListener('submit', addToCartHandler);
});
