import zilvercms from "./_utils";

const validate = field => {
    const validity = field.validity;
    const isValid = field.validity.valid;
    const errors = [];
    const translationPrefix = 'form.errors.';

    for(let key in validity) {
        if(key === 'valid' || !validity[key]) {
            continue;
        }

        if(key === 'typeMismatch' && field.type === 'email') {
            errors.push(zilvercms.translationHelper(`${translationPrefix}invalidEmail`));
        } else if(key === 'valueMissing' && field.type === 'email') {
            errors.push(zilvercms.translationHelper(`${translationPrefix}noEmail`));
        } else if(key === 'valueMissing' && field.name === 'data[invoice][streetnr]') {
            errors.push(zilvercms.translationHelper(`${translationPrefix}noStreetNo`));
        } else if(key === 'patternMismatch' && (field.name === 'data[invoice][streetnr]' || field.name === 'data[shipping][streetnr]' || field.name === 'Huisnummer')) {
            errors.push(zilvercms.translationHelper(`${translationPrefix}onlyNumbers`));
        } else if(key === 'patternMismatch') {
            errors.push(zilvercms.translationHelper(`${translationPrefix}patternMismatch`, {'%format%': field.title}));
        } else if(key === 'tooLong') {
            errors.push(zilvercms.translationHelper(`${translationPrefix}tooLong`, {'%characters%': field.maxLength}));
        } else if(key === 'tooShort') {
            errors.push(zilvercms.translationHelper(`${translationPrefix}tooShort`, {'%characters%': field.minLength}));
        } else if(key === 'customError') {
            errors.push(zilvercms.translationHelper(field.validationMessage));
        } else if(field.type === 'text'){
            errors.push(zilvercms.translationHelper(`${translationPrefix}noValue`, {'%field%': field.placeholder.toLowerCase()}));
        } else {
            errors.push(zilvercms.translationHelper(`${translationPrefix}${key}`));
        }
    }

    return [isValid, errors];
};

export default validate;
