import zilvercms from "./_utils";

const pakjegemak = {};

pakjegemak.resetTimeframes = () => {
    let $selectDate = $('.js-timeframe'),
        $selectOption = $('.js-timeframe-options'),
        $summaryDate = $('.js-summary-timeframe-date'),
        $summaryOption = $('.js-summary-timeframe-option'),
        $emptyOption = $('<option/>').text('Kies eerst een bezorgdatum').val('');

    $selectDate.removeAttr('name');

    $selectOption.html($emptyOption).removeAttr('name');
    $selectOption.parent().addClass('loading');

    $summaryDate.text($summaryDate.data('default'));
    $summaryOption.text($summaryOption.data('default'));

    pakjegemak.updateSummaryPrice('shippingcost', 0);
    pakjegemak.updateSummaryPrice('price', 0);
};

pakjegemak.formatTimeFrameDate = data => {
    const dateParts = data.date.split('-');
    const date = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}T00:00`);

    return `${zilvercms.translationHelper(`date.days.${date.getDay()}`)} ${date.getDate()} ${zilvercms.translationHelper(`date.months.${date.getMonth()}`)}`;
};

pakjegemak.updateSummaryPrice = (type, addingAmount) => {
    if(!type) {
        type = 'price';
    }
    let $price = $('.js-summary-'+type),
        plainPrice = $price.data('plain-price'),
        updatedPrice = plainPrice + addingAmount;

    $price.text(zilvercms.currencyHelper(updatedPrice));
};



export default pakjegemak;
