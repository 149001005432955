import zilvercms from "./_utils";
import Slider from "./_slider";

export const horizontalHandler = element => {
    if(element.hasAttribute('data-mobile') || zilvercms.viewport.isEqualOrGreaterThan('lg')) {
        new Slider(element, {
            sliderTrackSelector: '.js-horizontal-track',
            prevArrowSelector: '.js-horizontal-prev',
            nextArrowSelector: '.js-horizontal-next',
            initialSlide: element.dataset.selected || 0,
        });
    }
};

$('.js-horizontal').each((i, element) => horizontalHandler(element));
