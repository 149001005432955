import React, {useState} from 'react';

const Toggle = props => {
    const [checked, setChecked] = useState(props.checked || false);

    const onChangeHandler = e => {
        setChecked(e.currentTarget.checked);

        if(![null, undefined].includes(props.onChange)) {
            props.onChange(props.name, e.currentTarget.value, e.currentTarget.checked);
        }
    };

    return (
        <label className="input input--toggle">
            <div className="input__group">
                <input type="checkbox"
                       name={props.name}
                       value={props.value || 1}
                       checked={checked}
                       className="input__field"
                       onChange={onChangeHandler}
                />

                <span className="input__toggle toggle" />
                <div className="input__label-text">{props.label}</div>
            </div>
        </label>
    );
};

export default Toggle;
