import zilvercms from "./_utils";

const footerNavs = Object.values(document.getElementsByClassName('js-footer-nav'));

footerNavs.map(footerNav => {
    if(zilvercms.viewport.isEqualOrSmallerThan('md')) {
        const trigger = footerNav.getElementsByClassName('js-footer-nav-trigger')[0];
        const content = footerNav.getElementsByClassName('js-footer-nav-content')[0];

        trigger.addEventListener('click', () => {
            if(footerNav.classList.contains('footer__nav--open')) {
                content.style.height = 0;
                footerNav.classList.remove('footer__nav--open');
            } else {
                footerNav.classList.add('footer__nav--open');
                content.style.height = content.scrollHeight + 'px';
            }
        });
    }
});
