import React from 'react';
import ReviewStars from "./ReviewStars";
import zilvercms from "../scripts/_utils";

const Review = props => {
    const formatReviewDate = () => {
        const date = new Date(props.review.date);
        const difference = Math.round((+new Date - date) / 1000);

        const minute = 60;
        const hour = minute * 60;
        const day = hour * 24;
        const week = day * 7;
        const month = day * 31;
        const year = month * 12;

        let relativeDifference = '';
        let relativeAmount = 1;

        if(difference < minute) {
            relativeDifference = 'seconds';
        } else if(difference < (minute * 2)) {
            relativeDifference = 'minute';
        } else if(difference < hour) {
            relativeDifference = 'minutes';
            relativeAmount = Math.floor(difference / minute);
        } else if(Math.floor(difference / hour) === 1) {
            relativeDifference = 'hour';
        } else if(difference < day) {
            relativeDifference = 'hours';
            relativeAmount = Math.floor(difference / hour);
        } else if(difference < (day * 2)) {
            relativeDifference = 'day';
        } else if(difference < week) {
            relativeDifference = 'days';
            relativeAmount = Math.floor(difference / day);
        } else if(difference < (week * 2)) {
            relativeDifference = 'week';
        } else if(difference < month) {
            relativeDifference = 'weeks';
            relativeAmount = Math.floor(difference / week);
        } else if(difference < (month * 2)) {
            relativeDifference = 'month';
        } else if(difference < year) {
            relativeDifference = 'months';
            relativeAmount = Math.floor(difference / month);
        } else if(difference < (year * 2)) {
            relativeDifference = 'year'
        } else if(difference >= (year * 2)) {
            relativeDifference = 'years';
            relativeAmount = Math.floor(difference / year);
        }

        return `${relativeAmount} ${zilvercms.translationHelper(`timeline.date.${relativeDifference}`)}`;
    }

    return (
        <div className="review text">
            <div className="row">
                <div className="col-auto">
                    <div className="review__rating">
                        <span className="heading">{props.review.points}/5</span>
                    </div>
                </div>

                <div className="col col-xl-8 col-xxl-7 mx-lg-5">
                    <ReviewStars rating={props.review.points} />

                    <p className="d-lg-inline my-1 my-lg-0">
                        <strong className="ml-lg-3 align-middle">{props.review.name}</strong>
                        <span className="align-middle"> | <span title={props.review.date}>{formatReviewDate()}</span></span>
                    </p>

                    {zilvercms.viewport.isEqualOrGreaterThan('lg') && (
                        <div className="review__content mt-3 pt-2">
                            &ldquo;{props.review.review}&rdquo;
                        </div>
                    )}

                    {zilvercms.viewport.isEqualOrSmallerThan('md') && props.review.verified && (
                        <>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                                <path fill="#3C8935" fillRule="nonzero" d="M16.6868 9.6333c-.2833-.6988-.2833-1.4731 0-2.153l.0944-.2266c.6044-1.4353-.0944-3.0972-1.5297-3.7015l-.2077-.0944A2.7652 2.7652 0 0113.514 1.928l-.0756-.1888C12.8153.3039 11.1723-.376 9.7181.2095L9.5293.285a2.8813 2.8813 0 01-2.1719 0l-.17-.0755C5.7712-.376 4.1093.3228 3.5049 1.758l-.0755.151A2.7652 2.7652 0 011.8996 3.439l-.17.0755C.3132 4.1187-.3856 5.7806.2188 7.216l.0755.17c.2833.6988.2833 1.473 0 2.153l-.0755.2077c-.6044 1.4353.0755 3.0972 1.5297 3.6826l.1888.0756a2.7652 2.7652 0 011.5298 1.5297l.0944.2077c.5854 1.4542 2.2473 2.134 3.6826 1.5486l.2078-.0944a2.8813 2.8813 0 012.1718 0l.17.0755c1.4352.6043 3.0971-.0944 3.7015-1.5297l.0755-.151a2.7652 2.7652 0 011.5297-1.5298l.1511-.0566c1.4542-.6044 2.134-2.2474 1.5297-3.7016l-.0944-.17zm-8.9894 2.5495L4.0147 9.0856 5.148 7.7447l2.3417 1.983 4.136-4.9102 1.3408 1.1331-5.269 6.2322z"/>
                            </svg>

                            <span className="ml-2">{zilvercms.translationHelper('webshop.reviews.verified')}</span>
                        </>
                    )}
                </div>

                {zilvercms.viewport.isEqualOrSmallerThan('md') && (
                    <div className="col-12">
                        <div className="review__content mt-3">
                            &ldquo;{props.review.review}&rdquo;
                        </div>
                    </div>
                )}

                {zilvercms.viewport.isEqualOrGreaterThan('lg') && props.review.verified && (
                    <div className="col-auto ml-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
                            <path fill="#3C8935" fillRule="nonzero" d="M16.6868 9.6333c-.2833-.6988-.2833-1.4731 0-2.153l.0944-.2266c.6044-1.4353-.0944-3.0972-1.5297-3.7015l-.2077-.0944A2.7652 2.7652 0 0113.514 1.928l-.0756-.1888C12.8153.3039 11.1723-.376 9.7181.2095L9.5293.285a2.8813 2.8813 0 01-2.1719 0l-.17-.0755C5.7712-.376 4.1093.3228 3.5049 1.758l-.0755.151A2.7652 2.7652 0 011.8996 3.439l-.17.0755C.3132 4.1187-.3856 5.7806.2188 7.216l.0755.17c.2833.6988.2833 1.473 0 2.153l-.0755.2077c-.6044 1.4353.0755 3.0972 1.5297 3.6826l.1888.0756a2.7652 2.7652 0 011.5298 1.5297l.0944.2077c.5854 1.4542 2.2473 2.134 3.6826 1.5486l.2078-.0944a2.8813 2.8813 0 012.1718 0l.17.0755c1.4352.6043 3.0971-.0944 3.7015-1.5297l.0755-.151a2.7652 2.7652 0 011.5297-1.5298l.1511-.0566c1.4542-.6044 2.134-2.2474 1.5297-3.7016l-.0944-.17zm-8.9894 2.5495L4.0147 9.0856 5.148 7.7447l2.3417 1.983 4.136-4.9102 1.3408 1.1331-5.269 6.2322z"/>
                        </svg>

                        <span className="ml-2">{zilvercms.translationHelper('webshop.reviews.verified')}</span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Review;
