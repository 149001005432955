import zilvercms from "./_utils";

$('.js-popup').each((i, element) => {
    const popup = element;
    const popupName = element.dataset.popup;
    const openTriggers = document.querySelectorAll('.js-popup-trigger[data-popup="'+ popupName +'"]');
    const closeTriggers = popup.querySelectorAll('.js-popup-close');

    const openPopup = () => {
        zilvercms.lockBody();
        popup.classList.add('popup--open');
    };

    const closePopup = () => {
        document.body.dispatchEvent(new Event(`popup.close.${popupName}`))
        popup.classList.remove('popup--open');
        zilvercms.unlockBody();
    };

    window['popup.open.'+ popupName] = () => openPopup();
    window['popup.close.'+ popupName] = () => closePopup();

    Object.values(openTriggers).map(trigger => trigger.addEventListener('click', openPopup));
    Object.values(closeTriggers).map(trigger => trigger.addEventListener('click', closePopup));
});
