import zilvercms from "./_utils";
import Slider from "./_slider";

$('.js-productdetail').each((i, element) => {
    const productdetail = element;
    const $productdetail = $(element);

    // Images
    const $thumbnails = $productdetail.find('.js-productdetail-images-thumbnail');
    const imagesElement = element.querySelector('.js-productdetail-images');

    const slider = new Slider(imagesElement, {
        sliderTrackSelector: '.js-productdetail-images-track',
        prevArrowSelector:   '.js-productdetail-images-prev',
        nextArrowSelector:   '.js-productdetail-images-next',
        dots:                true,
        arrows:              false,
        viewports:           {
            lg: {
                arrows: true,
                dots:   false,
            }
        }
    });

    $thumbnails.on('click', e => {
        slider.goToSlide(e.currentTarget.dataset.index);
    });

    slider.on('change', currentSlide => {
        updateActiveThumnbail(currentSlide);
    });

    const updateActiveThumnbail = index => {
        const thumbnails = [...document.querySelectorAll('.js-productdetail-images-thumbnail')];

        thumbnails.map(thumbnail => {
            if (parseInt(thumbnail.dataset.index) === index) {
                thumbnail.setAttribute('data-active', '');
            } else {
                thumbnail.removeAttribute('data-active');
            }
        });
    };

    // Keep me posted
    const keepMePosted = productdetail.querySelector('.js-product-keep-me-posted');

    if(![null, undefined].includes(keepMePosted)) {
        keepMePosted.addEventListener('click', () => {
            window['popup.open.productdetail.keep-me-posted']();
        })
    }

    // Upsells
    const $upsells = $('.js-productdetail-upsell');

    $upsells.on('change', e => {
        const upsell = e.currentTarget;
        const upsellId = upsell.dataset.id;
        const isSelected = upsell.checked;
        const $selectedUpsell = $productdetail.find('.js-productdetail-selected-upsell[data-id="' + upsellId + '"]');

        if (isSelected) {
            $selectedUpsell.addClass('productdetail__selected-upsell--selected');
        } else {
            $selectedUpsell.removeClass('productdetail__selected-upsell--selected');
        }
    });

    // Specifications
    const $specifications = $productdetail.find('.js-product-specifications');
    const $specificationsToggle = $productdetail.find('.js-product-specifications-toggle');

    const toggleSpecificationsHeight = () => {
        let specLimit = 10;

        if (zilvercms.viewport.isEqualOrSmallerThan('md')) {
            specLimit = 4;
        }

        const $specLimit = $specifications.find('tr:nth-child(' + specLimit + ')');

        if ($specLimit.length === 1) {
            const heightLimit = $specLimit[0].offsetTop;

            if ($specifications[0].scrollHeight < heightLimit) {
                $specificationsToggle.hide();

                return;
            }

            if ($specifications.outerHeight() === heightLimit) {
                $specifications.height($specifications[0].scrollHeight);
                $specifications.addClass('productdetail__specifications--open');
            } else {
                $specifications.height(heightLimit);
                $specifications.removeClass('productdetail__specifications--open');
            }
        } else {
            $specificationsToggle.hide();
        }
    };

    toggleSpecificationsHeight();
    $specificationsToggle.on('click', toggleSpecificationsHeight);
});
