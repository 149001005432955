import utils from './_utils';

if(utils.viewport.isEqualOrGreaterThan('lg')) {
    const linksContainer = document.getElementsByClassName('js-faq-links')[0];

    if(linksContainer !== undefined) {
        const links = [...linksContainer.getElementsByClassName('js-faq-link')];
        const contents = [...document.getElementsByClassName('js-faq-content')];

        const scrollHandler = () => {
            const scrollTop = window.scrollY;
            let currentIndex = contents.length;

            for(let i = 0; i < contents.length; i++) {
                if((contents[i].offsetTop + (contents[i].clientHeight * .85)) >= scrollTop) {
                    currentIndex = i;
                    break;
                }
            }

            links.map((link, index) => link.classList[index === currentIndex ? 'add' : 'remove']('active'));
        };

        links.map(link => link.addEventListener('click', e => {
            const content = contents.find(content => content.id === link.href.split('#')[1]);

            if(![null, undefined].includes(content)) {
                e.preventDefault();

                window.scrollTo({behavior: 'smooth', top: content.offsetTop})
            }
        }));

        scrollHandler();
        window.addEventListener('scroll', scrollHandler);
    }
}
