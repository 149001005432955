import PopupAddReview from "../components/PopupAddReview";
import React from "react";
import ReactDOM from "react-dom";

const popupTrigger = document.querySelector('.js-review-popup-trigger'),
      popupContainer = document.querySelector('.js-reviews-popup-container');

const closePopup = () => {
    ReactDOM.unmountComponentAtNode(popupContainer);
};

if(![null,undefined].includes(popupTrigger) && ![null, undefined].includes(popupContainer)) {
    popupTrigger.addEventListener('click', (e) => {
        e.preventDefault();
        ReactDOM.render(<PopupAddReview id={popupTrigger.dataset.id} closePopup={() => closePopup()} />, popupContainer);
    });
}
