import wishlist from './_wishlist';

wishlist.init();

$('body').on('click', '.js-wishlist-trigger', async e => {
    e.preventDefault();
    const trigger = e.currentTarget;
    const productId = parseInt(trigger.dataset.productId);
    let newWishlistCount = 0;

    if(trigger.classList.contains('wishlist-trigger--in-wishlist')) {
        newWishlistCount = await wishlist.removeProduct(productId);

        if(newWishlistCount !== false) {
            trigger.classList.remove('wishlist-trigger--in-wishlist');
        }
    } else {
        newWishlistCount = await wishlist.addProduct(productId);

        if(newWishlistCount !== false) {
            trigger.classList.add('wishlist-trigger--in-wishlist');
        }
    }

    $('.js-wishlist-count').attr('data-count', newWishlistCount);
});

const wishlistProducts = [...document.querySelectorAll('.js-wishlist-productblock')];

wishlistProducts.map(product => {
    const removeTrigger = product.querySelector('.js-wishlist-productblock-remove');

    removeTrigger.addEventListener('click', async e => {
        e.preventDefault();

        let newWishlistCount = await wishlist.removeProduct(parseInt(product.dataset.id));

        if(newWishlistCount !== false) {
            product.remove();

            if(newWishlistCount === 0) {
                document.querySelector('.js-wishlist-empty').setAttribute('data-empty', '')
            }

            [...document.querySelectorAll('.js-wishlist-count')].map(wishlistCount => {
                wishlistCount.setAttribute('data-count', newWishlistCount);
            });
        }
    })
});

// Wishlist Share Function
const wishlistShareTrigger = document.querySelector('.js-wishlist-share');
const wishlistCopyShare = document.querySelector('.js-wishlistmodal-copy');

if(wishlistShareTrigger !== null) {
    wishlistShareTrigger.addEventListener('click', () => {
        const shareUrl = `https://${window.location.hostname}/share/?id=${wishlistShareTrigger.dataset.id}`;

        if(window.navigator.share) {
            window.navigator.share({
                title: wishlistShareTrigger.innerText,
                text: `${wishlistShareTrigger.dataset.text}: `,
                url: shareUrl,
            }).catch(console.error);
        } else {

            window['popup.open.wishlistmodal']();

            const fallback = document.querySelector('.js-wishlist-share-fallback');
            const fallbackInput = fallback.querySelector('input');

            if(window.navigator.clipboard !== undefined) {
                window.navigator.clipboard.writeText(shareUrl);
            }

            wishlistCopyShare.addEventListener('click', () => {
                if(window.navigator.clipboard !== undefined) {
                    window.navigator.clipboard.writeText(shareUrl);
                }
            });

            fallbackInput.value = shareUrl;
        }
    });




}


