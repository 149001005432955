import Cookie from './_cookie';
import axios from "axios";

const countrymodal = document.querySelector('.js-countrymodal');

if(countrymodal !== null) {
    const checkCountryCode = async () => {
        const showCountryModel = JSON.parse(Cookie.get('countryModel')) !== '1';

        if(showCountryModel) {
            let currentCountryCode = JSON.parse(Cookie.get('countryCode')) || '';

            if(currentCountryCode === '') {
                let response = await axios.get('https://get.geojs.io/v1/ip/geo.json');

                if(response.status === 200) {
                    currentCountryCode = response.data.country_code.toLowerCase();
                    Cookie.set('countryCode', JSON.stringify(currentCountryCode), 1);
                }
            }

            if(document.documentElement.lang !== currentCountryCode) {
                const countrymodalTemplate = document.querySelector(`.js-countrymodel-template[data-for="${currentCountryCode}"]`);

                if(countrymodalTemplate !== null) {
                    countrymodal.innerHTML = countrymodalTemplate.innerHTML;

                    [...countrymodal.querySelectorAll('.js-popup-close')].map(trigger => trigger.addEventListener('click', () => {
                        window['popup.close.countrymodal']();
                    }));

                    window['popup.open.countrymodal']();
                    Cookie.set('countryModel', JSON.stringify(1), 1);
                }
            }
        }
    };

    checkCountryCode();
}
